import { ActionType } from './actionTypes';

export const setSharingMethodDataToStore = (
  paginationData: any = {
    previous: null,
    next: null,
    hasPrevious: false,
    hasNext: false,
    total: 0,
    results: [],
  }
) => {
  return {
    type: ActionType.ADD_SHARING_METHODS,
    payload: paginationData,
  };
};
