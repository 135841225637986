import { ActionType } from './actionTypes';

export const setLinkDataSetDataToStore = (
  paginationData: any = {
    previous: null,
    next: null,
    hasPrevious: false,
    hasNext: false,
    total: 0,
    results: [],
  }
) => {
  return {
    type: ActionType.GET_LINK_DATA_SET_DATA,
    payload: paginationData,
  };
};

export const displayToastMessage = (toast: any = null) => {
  return {
    type: ActionType.DISPLAY_TOAST,
    payload: toast,
  };
};

export const setLinkDataSet = (payload: any = null) => {
  return {
    type: ActionType.GET_LINK_DATA_SET,
    payload,
  };
};
