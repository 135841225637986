import React from 'react';
import Header from './Header';
import StickyNav from './StickyNav';

const Layout = ({ children }: any) => {
  return (
    <div className='main-layout'>
      <StickyNav />
      <div className='main-container'>
        <Header />
        <main>{children}</main>
      </div>
    </div>
  );
};

export default Layout;
