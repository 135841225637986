import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';
import QuestionTextInput from '../../QuestionTextInput';

const OpenQuestionTranslateComponent = ({ data }: any) => {
  const { questionnaire, question, languageCode } = data;

  const {
    control,
    formState: { errors },
    reset,
  } = useFormContext<any>();

  useEffect(() => {
    const questionData = question.questions.find(
      (questionData: any) => questionData.language?.code === languageCode
    );

    reset({
      [languageCode]: {
        id: questionData?.text?.id || null,
        label: questionData?.text?.main || null,
        sub: questionData?.text?.sub || null,
        placeholder: questionData?.text?.placeholder || null,
      },
    });
  }, [question, questionnaire, reset, languageCode]);

  return (
    <div className='page-question-translater-wrappers'>
      <div className='form-group'>
        <label>Question Text</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.label ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.label`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Start typing question text...'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>
      <div className='form-group'>
        <label>Description</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.sub ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.sub`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Add description to your question'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>
      <div className='form-group'>
        <label>Placeholder</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.placeholder ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.placeholder`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Add placeholder to your question'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default OpenQuestionTranslateComponent;
