import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Modal from 'react-modal';
import Select from 'react-select';
import csvtojson from 'csvtojson';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import { customModal } from '../../Constants/ThemeO2T';

const AddEditLinkDataSetModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  isEdit,
  modalData,
  onSubmit,
  error,
  isLoading,
}) => {
  const modalTitle = isEdit ? 'Edit Link Data Set' : 'Add Link Data Set';
  const {
    register,
    reset,
    formState: { errors },
    getValues,
    control,
    setValue,
  } = useForm<any>();
  const [name, setName] = useState(modalData?.name || '');

  const dataTypeOptions: any = useMemo(
    () => [{ label: 'Email List', value: 'EMAIL_LIST' }],
    []
  );

  const [file, setFile] = useState(null);
  const [jsonData, setJsonData] = useState(null);

  const fileReader = useMemo(() => new FileReader(), []);

  async function convertCSVToJson(options: Object, csv: string) {
    const jsonArray = await csvtojson(options).fromString(csv);
    return jsonArray;
  }

  const resetForm = useCallback(async () => {
    setFile(null);
    reset({ name: '' });
  }, [reset]);

  const closeModal = () => {
    resetForm();
    toggleModal();
  };

  const handleFileOnChange = (e: any) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    setValue('dataType', dataTypeOptions[0].value);
    const { name, dataType } = getValues();
    const variables: any = {
      name,
      dataType,
      data: jsonData,
    };

    if (isEdit) {
      variables.linkDataSetId = modalData.selectedLinkDataSet._id;
    }

    onSubmit({ variables });
    resetForm();
  };

  useEffect(() => {
    // check the documentation for more information -> https://www.npmjs.com/package/csvtojson?activeTab=readme
    const csvParserOptions = {
      noheader: true,
      headers: ['email'],
      trim: true,
      alwaysSplitAtEOL: true,
      output: 'json',
    };

    async function processCsvData(file: any) {
      fileReader.readAsText(file);
      fileReader.onload = async function (event: any) {
        const csvOutput = event.target.result;
        const json: any = await convertCSVToJson(csvParserOptions, csvOutput);
        setJsonData(json);
      };
    }

    if (file) {
      processCsvData(file);
    }
  }, [file, fileReader]);

  useEffect(() => {
    reset({
      name: modalData?.selectedLinkDataSet?.name,
    });
  }, [modalData, reset]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Add LinkDataSet Modal'
        style={{
          content: {
            ...customModal.content,
            width: '25%',
            height: 'fit-content',
            maxHeight: '90%',
            overflow: 'hidden',
          },
        }}
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <form onSubmit={handleSubmit}>
              <div className='linkDataSet-add-edit'>
                <div className='form-group'>
                  <label>
                    Name <span className='mandatory'>*</span>
                  </label>
                  <input
                    data-testid='linkDataSet-name-input'
                    {...register('name', { required: true })}
                    onChange={(e) => setName(e.target.value)}
                    type='text'
                    value={name}
                  />
                  {errors.name && (
                    <span className='error-msg'>Name is required.</span>
                  )}
                </div>

                {!isEdit && (
                  <>
                    <div className='form-group'>
                      <div className='w-50'>
                        <label>
                          Data Type <span className='mandatory'>*</span>
                        </label>
                        <Controller
                          control={control}
                          name='dataType'
                          rules={{ required: false }}
                          render={({ field: { onChange, value } }) => (
                            <Select
                              defaultValue={dataTypeOptions[0]}
                              options={dataTypeOptions}
                              onChange={onChange}
                              value={value}
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className='form-group'>
                      <input
                        data-testid='linkDataSet-name-input'
                        type={'file'}
                        id={'csvFileInput'}
                        name={'csvFileInput'}
                        accept={'.csv'}
                        onChange={handleFileOnChange}
                        multiple={false}
                      />
                    </div>
                  </>
                )}

                <div className='alert-wrapper'>
                  {error && <p>{error.message}</p>}
                </div>

                <div
                  className='o2t-modal__footer'
                  style={{ paddingTop: '1rem' }}
                >
                  <button
                    onClick={closeModal}
                    className='link-button mr-2'
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    data-testid='linkDataSet-save-btn'
                    disabled={isLoading}
                  >
                    {isEdit ? 'Save' : 'Add Link Data Set'}
                    <span>
                      <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEditLinkDataSetModal;
