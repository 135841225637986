import { applyMiddleware, createStore } from 'redux';
import thunk from 'redux-thunk';
import { persistedReducer } from './Reducers';
import { composeWithDevTools } from '@redux-devtools/extension';
import { persistStore } from 'redux-persist';

const middleware = [thunk];

if (process.env.REACT_APP_LOG_REDUX === `true`) {
  const { logger } = require(`redux-logger`);
  middleware.push(logger);
}

export * from './Hooks';
export * from './Reducers';
export const store = createStore(
  persistedReducer,
  {},
  composeWithDevTools(applyMiddleware(...middleware))
);

export const persister = persistStore(store);
