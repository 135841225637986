import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { QuestionTypes } from '../../../Utils/types';
import AccessControl from '../../AccessControl';
import ChoicesQuestion from './ChoicesQuestion';
import ConditionalQuestion from './ConditionalQuestion';
import CustomFinalPage from './CustomFinalPage';
import CustomStartingPage from './CustomStartingPage';
import FinalPage from './FinalPage';
import FormQuestion from './FormQuestion';
import InfoPage from './InfoPage';
import MatrixQuestion from './MatrixQuestion';
import OpenQuestion from './OpenQuestion';
import QuestionConditionalComponent from './QuestionUtil/QuestionConditionalComponent';
import {
  formatChoicesQuestionData,
  formatCustomHTMLPageQuestionData,
  formatFinalQuestionData,
  formatFormQuestionData,
  formatInfoQuestionData,
  formatMatrixChoiceQuestionData,
  formatMatrixRatingQuestionData,
  formatOpenQuestionData,
  formatRankingQuestionData,
  formatRatingQuestionData,
  formatStartingPageData,
} from './QuestionUtil/QuestionDataHandler';
import RankingQuestion from './RankingQuestion';
import RatingQuestion from './RatingQuestion';
import StartingPage from './StartingPage';

const choiceQuestionTypes = [
  QuestionTypes.SINGLE_CHOICE,
  QuestionTypes.MULTIPLE_CHOICE,
  QuestionTypes.CHOICE_PAGE,
];
const ratingQuestionTypes = [QuestionTypes.RATING, QuestionTypes.RATING_PAGE];

const QuestionWrapper = ({ data, onQuestionFormEdit }: any) => {
  const {
    questionnaire,
    questions,
    question,
    handleUpdateQuestion,
    isLoading,
  } = data;
  const methods = useForm<any>();
  const { formState, getValues, setValue } = methods;
  const [choices, setChoices] = useState<any>([]);
  const [answers, setAnswers] = useState<any>([]);
  const [selectedQuestionsList, setSelectedQuestionsList] = useState<any[]>([
    {},
  ]);
  const [conditions, setConditions] = useState<any[]>([]);
  const [previousQuestion, setPreviousQuestion] = useState<any>(
    question.parentQuestion
  );
  const [feedbackQuestion, setFeedbackQuestion] = useState<any>(
    question.followupQuestion
  );
  const [range, setRange] = useState<any>(
    question?.parentQuestion?.type === QuestionTypes.RATING
      ? question.conditions
      : [0, 1]
  );
  const [selectedChoices, setSelectedChoices] = useState<any>(
    question?.parentQuestion?.type === QuestionTypes.SINGLE_CHOICE ||
      question?.parentQuestion?.type === QuestionTypes.MULTIPLE_CHOICE
      ? question.conditions
      : []
  );

  const [matrixQuestionChoice, setMatrixQuestionChoice] = useState<any>(
    question?.matrixQuestionChoice || null
  );

  useEffect(() => {
    onQuestionFormEdit(formState.isDirty);
  }, [formState, onQuestionFormEdit]);

  const getSelectedChoices = (question: any) => {
    let questionType: any = null;
    const conditions = question.conditions;
    if (question?.parentQuestion?.type === QuestionTypes.CONDITIONAL) {
      const parentConditionalQuestion = question?.parentQuestion;
      questionType = parentConditionalQuestion?.question?.followupQuestion.type;
    } else {
      questionType = question?.parentQuestion?.type;
    }
    return choiceQuestionTypes.indexOf(questionType) !== -1 ? conditions : [];
  };

  const getSelectedRange = (question: any) => {
    let questionType: any = null;
    const conditions = question.conditions;
    if (question?.parentQuestion?.type === QuestionTypes.CONDITIONAL) {
      const parentConditionalQuestion = question?.parentQuestion;
      questionType = parentConditionalQuestion?.question?.followupQuestion.type;
    } else {
      questionType = question?.parentQuestion?.type;
    }
    return ratingQuestionTypes.indexOf(questionType) !== -1
      ? conditions
      : [0, 1];
  };

  useEffect(() => {
    setPreviousQuestion(question.parentQuestion || null);
    setFeedbackQuestion(question.followupQuestion || null);

    const selectedRange = getSelectedRange(question);
    setRange(selectedRange);

    const selectedChoices = getSelectedChoices(question);
    setSelectedChoices(selectedChoices);

    setMatrixQuestionChoice(
      question?.parentQuestion?.type === QuestionTypes.RATING_PAGE ||
        question?.parentQuestion?.type === QuestionTypes.CHOICE_PAGE
        ? question?.matrixQuestionChoice
        : null
    );
    setConditions(question.conditions || []);
  }, [question]);

  const onSubmit = (formData: any) => {
    let type = question.type;
    if (question.type === 'SINGLE_CHOICE' && formData.MCQ)
      type = 'MULTIPLE_CHOICE';
    if (question.type === 'MULTIPLE_CHOICE' && formData.CQ)
      type = 'SINGLE_CHOICE';
    console.log(formData.condition);
    const questionData = getQuestionsDataForForm(formData, type, questions);
    console.log(questionData);
    handleUpdateQuestion(questionData);
  };

  const getQuestionsDataForForm = (
    formData: any,
    type: string,
    questionsList: any[]
  ) => {
    switch (type) {
      case QuestionTypes.LANDING:
        return formatStartingPageData(formData, data, questionsList, type);
      case QuestionTypes.OPEN:
        return formatOpenQuestionData(formData, data, questionsList, type);
      case QuestionTypes.RATING:
        return formatRatingQuestionData(
          formData,
          data,
          choices,
          questionsList,
          type
        );
      case QuestionTypes.SINGLE_CHOICE:
        return formatChoicesQuestionData(
          formData,
          data,
          choices,
          questionsList,
          type
        );
      case QuestionTypes.MULTIPLE_CHOICE:
        return formatChoicesQuestionData(
          formData,
          data,
          choices,
          questionsList,
          type
        );
      case QuestionTypes.INFO:
        return formatInfoQuestionData(formData, data, questionsList, type);
      case QuestionTypes.FINAL:
        return formatFinalQuestionData(formData, data, questionsList, type);
      case QuestionTypes.CONDITIONAL:
        return formatConditionalQuestionData(formData, questionsList, type);
      case QuestionTypes.CHOICE_PAGE:
        return formatMatrixChoiceQuestionData(
          formData,
          data,
          choices,
          answers,
          questionsList,
          type
        );
      case QuestionTypes.RATING_PAGE:
        return formatMatrixRatingQuestionData(
          formData,
          data,
          choices,
          answers,
          questionsList,
          type
        );
      case QuestionTypes.CUSTOM_LANDING:
        return formatCustomHTMLPageQuestionData(
          formData,
          data,
          questionsList,
          type
        );
      case QuestionTypes.CUSTOM_FINAL:
        return formatCustomHTMLPageQuestionData(
          formData,
          data,
          questionsList,
          type
        );
      case QuestionTypes.RANKING:
        return formatRankingQuestionData(
          formData,
          data,
          choices,
          questionsList,
          type
        );
      case QuestionTypes.FORM:
        return formatFormQuestionData(
          formData,
          data,
          selectedQuestionsList,
          questionsList,
          type
        );
    }
  };

  const getConditions = (question: any) => {
    const parentQuestion =
      question.type === QuestionTypes.CONDITIONAL
        ? question?.question?.questions?.[0]?.followupQuestion
        : question;
    return parentQuestion.type === QuestionTypes.RATING ||
      parentQuestion.type === QuestionTypes.RATING_PAGE
      ? range
      : selectedChoices;
  };

  const formatConditionalQuestionData = (
    formData: any,
    questionsList: any[],
    type?: string
  ) => {
    const followupQuestionData: any = getQuestionsDataForForm(
      formData,
      feedbackQuestion.type,
      questionsList
    );

    const questionData: any = {
      parentQuestion: previousQuestion,
      conditions: getConditions(previousQuestion),
      followupQuestion: { ...followupQuestionData?.variables },
    };

    if (
      previousQuestion.type === QuestionTypes.RATING_PAGE ||
      previousQuestion.type === QuestionTypes.CHOICE_PAGE
    ) {
      questionData.matrixQuestionChoice = matrixQuestionChoice;
    }

    return {
      variables: {
        questionId: question._id,
        label: formData[questionnaire.supportedLanguages[0].code].label,
        class: question.class,
        type: type || question.type,
        followupParentId: previousQuestion.value,
        questions: [questionData],
        attributes: {
          optional: formData.optional || false,
        },
      },
    };
  };

  const handleDeleteCondition = (conditionIndex: number) => {
    const filteredConditions = conditions.filter(
      (_condition, index: number) => conditionIndex !== index
    );

    const { condition } = getValues();
    setValue(
      'condition',
      condition.filter(
        (element: any, index: number) => index !== conditionIndex
      )
    );

    setConditions(filteredConditions);
  };

  const handleAddCondition = (event: any) => {
    event.preventDefault();
    const { condition } = getValues();
    setConditions([...(condition || []), {}]);
  };

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(onSubmit)}
          className='question-wrapper-form'
        >
          <div className='question-container'>
            <div className='question-wrapper__list'>
              <div className='q-content'>
                <div>
                  <div className='inner-tabs'>
                    {question?.type === QuestionTypes.LANDING && (
                      <StartingPage
                        data={{
                          questionnaire,
                          question,
                          settings: false,
                          isLoading,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.CUSTOM_LANDING && (
                      <CustomStartingPage
                        data={{
                          questionnaire,
                          question,
                          settings: false,
                          isLoading,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.OPEN && (
                      <OpenQuestion
                        data={{
                          questionnaire,
                          question,
                          settings: false,
                          isLoading,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.RATING && (
                      <RatingQuestion
                        data={{
                          questionnaire,
                          question,
                          settings: false,
                          isLoading,
                          choices,
                          setChoices,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.SINGLE_CHOICE && (
                      <ChoicesQuestion
                        data={{
                          questionnaire,
                          question,
                          settings: false,
                          isLoading,
                          singleChoice: true,
                          choices,
                          setChoices,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.MULTIPLE_CHOICE && (
                      <ChoicesQuestion
                        data={{
                          questionnaire,
                          question,
                          settings: false,
                          isLoading,
                          singleChoice: false,
                          choices,
                          setChoices,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.INFO && (
                      <InfoPage
                        data={{
                          questionnaire,
                          question,
                          settings: false,
                          isLoading,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.CONDITIONAL && (
                      <ConditionalQuestion
                        data={{
                          questionnaire,
                          question,
                          questions,
                          settings: false,
                          isLoading,
                          choices,
                          setChoices,
                          previousQuestion,
                          setPreviousQuestion,
                          feedbackQuestion,
                          setFeedbackQuestion,
                          range,
                          setRange,
                          selectedChoices,
                          setSelectedChoices,
                          answers,
                          setAnswers,
                          matrixQuestionChoice,
                          setMatrixQuestionChoice,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.FINAL && (
                      <FinalPage
                        data={{
                          questionnaire,
                          question,
                          questions,
                          settings: false,
                          isLoading,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.CUSTOM_FINAL && (
                      <CustomFinalPage
                        data={{
                          questionnaire,
                          question,
                          questions,
                          settings: false,
                          isLoading,
                        }}
                      />
                    )}
                    {(question?.type === QuestionTypes.RATING_PAGE ||
                      question?.type === QuestionTypes.CHOICE_PAGE) && (
                      <MatrixQuestion
                        data={{
                          questionnaire,
                          question,
                          questions,
                          settings: false,
                          isLoading,
                          choices,
                          setChoices,
                          answers,
                          setAnswers,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.RANKING && (
                      <RankingQuestion
                        data={{
                          questionnaire,
                          question,
                          settings: false,
                          choices,
                          setChoices,
                        }}
                      />
                    )}
                    {question?.type === QuestionTypes.FORM && (
                      <FormQuestion
                        data={{
                          questionnaire,
                          question,
                          questions,
                          selectedQuestionsList,
                          setSelectedQuestionsList,
                          settings: false,
                        }}
                      />
                    )}
                  </div>
                  {question?.type !== QuestionTypes.CONDITIONAL && (
                    <>
                      <div className='conditions-container'>
                        {conditions.map((condition, index) => {
                          return (
                            <div
                              key={index}
                              style={{
                                marginBottom: '3rem',
                                paddingLeft: '15px',
                              }}
                            >
                              <QuestionConditionalComponent
                                data={{
                                  index,
                                  condition,
                                  conditions,
                                  questions,
                                  question,
                                }}
                                onDeleteCondition={handleDeleteCondition}
                              />
                            </div>
                          );
                        })}
                      </div>
                      <div className='condition-btn-wrapper'>
                        <button
                          onClick={handleAddCondition}
                          className='condition-btn'
                        >
                          + Add condition
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className='divider' />
              <div className='q-settings'>
                <span className='header-title'>Settings</span>
                {question?.type === QuestionTypes.LANDING && (
                  <StartingPage
                    data={{
                      questionnaire,
                      question,
                      settings: true,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.CUSTOM_LANDING && (
                  <CustomStartingPage
                    data={{
                      questionnaire,
                      question,
                      settings: true,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.OPEN && (
                  <OpenQuestion
                    data={{
                      questionnaire,
                      question,
                      settings: true,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.RATING && (
                  <RatingQuestion
                    data={{
                      questionnaire,
                      question,
                      settings: true,
                      choices,
                      setChoices,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.SINGLE_CHOICE && (
                  <ChoicesQuestion
                    data={{
                      questionnaire,
                      question,
                      settings: true,
                      singleChoice: true,
                      choices,
                      setChoices,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.MULTIPLE_CHOICE && (
                  <ChoicesQuestion
                    data={{
                      questionnaire,
                      question,
                      settings: true,
                      singleChoice: false,
                      choices,
                      setChoices,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.INFO && (
                  <InfoPage
                    data={{
                      questionnaire,
                      question,
                      settings: true,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.CONDITIONAL && (
                  <ConditionalQuestion
                    data={{
                      questionnaire,
                      question,
                      questions,
                      settings: true,
                      choices,
                      setChoices,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.FINAL && (
                  <FinalPage
                    data={{
                      questionnaire,
                      question,
                      questions,
                      settings: true,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.CUSTOM_FINAL && (
                  <CustomFinalPage
                    data={{
                      questionnaire,
                      question,
                      questions,
                      settings: true,
                    }}
                  />
                )}
                {(question?.type === QuestionTypes.RATING_PAGE ||
                  question?.type === QuestionTypes.CHOICE_PAGE) && (
                  <MatrixQuestion
                    data={{
                      questionnaire,
                      question,
                      questions,
                      settings: true,
                      isLoading,
                      choices,
                      setChoices,
                      answers,
                      setAnswers,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.RANKING && (
                  <RankingQuestion
                    data={{
                      questionnaire,
                      question,
                      settings: true,
                      choices,
                      setChoices,
                    }}
                  />
                )}
                {question?.type === QuestionTypes.FORM && (
                  <FormQuestion
                    data={{
                      questionnaire,
                      question,
                      questions,
                      selectedQuestionsList,
                      setSelectedQuestionsList,
                      settings: true,
                    }}
                  />
                )}
                <div className='btn-pre-wrapper'>
                  <AccessControl allowedPermissions={['update:question']}>
                    <button
                      type='submit'
                      className='save-btn'
                      disabled={isLoading}
                    >
                      Save
                    </button>
                  </AccessControl>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

export default QuestionWrapper;
