import React from 'react';
import loadingSpinner from '../../assets/loading.svg';

const Loading = () => {
  return (
    <div className='loading-screen'>
      <img src={loadingSpinner} alt='Loading..' />
    </div>
  );
};

export default Loading;
