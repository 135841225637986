import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { customModal } from '../../Constants/ThemeO2T';

const SSOUserRedirectModal: React.FC<any> = ({
  modalIsOpen,
  modalTitle,
  onRedirect,
  isLoading,
}) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customModal}
        contentLabel='Profile redirect confirmation modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <p>
              Seems like you are using Single sign-on with your provider. Please
              merge your single sign-on user with the QMS user
            </p>
            <p>Redirecting to the profile section to link accounts</p>
          </div>
          <div className='o2t-modal__footer'>
            <button onClick={() => onRedirect()} disabled={isLoading}>
              Profile
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SSOUserRedirectModal;
