import DataTable, { createTheme } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye } from '@fortawesome/free-solid-svg-icons';

import { TableTheme, customTable } from '../../Constants/ThemeO2T';
import { NavLink } from 'react-router-dom';
import Loading from '../Basic/Loading';
import { formatDate } from '../../Utils/util';
import { RegionFactory } from '../../Utils/regions';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';

createTheme(
  'o2t-table',
  {
    text: {
      primary: TableTheme.tTextPrimary,
    },
    background: {
      default: TableTheme.tBg,
    },
    divider: {
      default: TableTheme.tRowBorder,
    },
  },
  'light'
);

const LicenseGrid = (props: any) => {
  const { data } = props;
  const { next, previous, paginationDetails } = data;

  const totalRows = data.total || null;

  const regions = new RegionFactory();

  // Table columns configuration
  const columns = [
    {
      name: 'Name',
      selector: (row: any) => (row.name ? row.name : 'Name'),
      sortable: true,
    },
    {
      name: 'Region',
      selector: (row: any) =>
        row.dataRegion
          ? regions
              .getRegions()
              .find((region) => region.regionCode === row.dataRegion)
              ?.regionName
          : 'Region',
      sortable: true,
    },
    {
      name: 'Product',
      selector: (row: any) => (row.product ? row.product?.productName : ''),
      sortable: true,
    },
    {
      name: 'Template',
      selector: (row: any) => (row.template ? row.template : 'Basic'),
      sortable: true,
    },
    {
      name: 'Created at',
      selector: (row: any) =>
        row.createdAt ? formatDate(new Date(Number(row.createdAt))) : 'Date',
      sortable: true,
    },
    {
      name: 'Expire at',
      selector: (row: any) =>
        row.expireAt ? formatDate(new Date(Number(row.expireAt))) : 'Date',
      sortable: true,
    },
    {
      button: true,
      cell: (row: any) => (
        <div className='actions-col'>
          <AccessControl allowedPermissions={['view:license']}>
            <NavLink className='btn-theme' to={`licenses/view/${row._id}`}>
              <FontAwesomeIcon icon={faEye} />
            </NavLink>
          </AccessControl>
          <span className='divider'>|</span>
          <AccessControl allowedPermissions={['delete:license']}>
            <button
              className='btn-delete'
              onClick={() => onLicenseDeleteClick(row)}
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </AccessControl>
        </div>
      ),
    },
  ];

  const onLicenseDeleteClick = (license: any) => {
    data.handleDeleteLicenseSelect(license);
  };

  const onPageChange = (page: number) => {
    const pagination: any =
      paginationDetails.page < page ? { next } : { previous };

    data.handlePageChange({
      ...pagination,
      limit: paginationDetails.limit,
      page,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const onPerRowsChange = (perPage: number) => {
    data.handlePageChange({
      limit: perPage,
      page: 1,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  return (
    <>
      <AccessControl
        allowedPermissions={['list:licenses']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view licenses'
            }
          />
        )}
      >
        <DataTable
          columns={columns}
          data={data.licenses}
          theme='o2t-table'
          customStyles={customTable}
          progressPending={data.loading}
          progressComponent={<Loading />}
          pagination={true}
          paginationServer={true}
          onSort={data.handleSortByColumn}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={onPerRowsChange}
          onChangePage={onPageChange}
        />
      </AccessControl>
    </>
  );
};

export default LicenseGrid;
