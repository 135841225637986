import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Controller, useForm } from 'react-hook-form';
import { customModal } from '../../Constants/ThemeO2T';
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { useLazyQuery } from '@apollo/client';
import { QUESTIONS } from 'src/Query/questions.query';
import { QuestionTypes } from 'src/Utils/types';

const animatedComponents = makeAnimated();

const options = [
  {
    label: 'Independent variable',
    value: 'Independent',
  },
  {
    label: 'Dependent variable',
    value: 'Dependent',
  },
  {
    label: 'Exclude',
    value: 'Exclude',
  },
];

const AddEditAnalyitcsConfigModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  isEdit,
  modalData,
  onSubmit,
  onScroll,
}) => {
  const modalTitle = isEdit
    ? 'Edit analytics configuration'
    : 'Add analytics configuration';

  const [questionnaireList, setQuestionnaireList] = useState<any[]>([]);
  const [selectedQuestionnaire, setSelctedQuestionnaire] = useState<any>(null);
  const [questionsList, setQuestionsList] = useState<any[]>([]);
  const [dataList, setDataList] = useState<any[]>([]);

  const { questionnaires, selectedConfig } = modalData;

  const {
    register,
    reset,
    getValues,
    formState: { errors },
    control,
    watch,
  } = useForm<any>();

  const questionnaireWatch = watch('questionnaire');

  useEffect(() => {
    reset({
      name: selectedConfig?.name,
      questionnaire: {
        value: selectedConfig?.questionnaireId,
        label: selectedConfig?.questionnaireName,
      },
      label: selectedConfig?.data.map((dataValue: any) =>
        options.find((option) => option.value === dataValue.value)
      ),
    });
    setSelctedQuestionnaire(
      questionnaires.find(
        (questionnaire: any) =>
          questionnaire._id === selectedConfig?.questionnaireId
      )
    );
    setDataList(selectedConfig?.data || []);
  }, [selectedConfig, reset, questionnaires]);

  useEffect(() => {
    setQuestionnaireList(
      questionnaires.map((questionnaire: any) => {
        return {
          label: questionnaire.name,
          value: questionnaire._id,
        };
      })
    );
  }, [questionnaires]);

  useEffect(() => {
    if (questionnaireWatch?.value) {
      const selectedQuestionnaire = modalData?.questionnaires?.find(
        (questionnaire: any) => questionnaire._id === questionnaireWatch.value
      );
      setSelctedQuestionnaire(selectedQuestionnaire);
    }
  }, [questionnaireWatch, modalData.questionnaires]);

  const [getQuestions] = useLazyQuery(QUESTIONS, {
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      setQuestionsList(
        completedData.questions?.results.filter(
          (question: any) => question.type === QuestionTypes.RATING
        )
      );
    },
  });

  useEffect(() => {
    if (selectedQuestionnaire) {
      getQuestions({
        variables: {
          limit: 1000,
          page: 1,
          questionnaireId: selectedQuestionnaire._id,
        },
      });
    }
  }, [selectedQuestionnaire, getQuestions]);

  const closeModal = () => {
    toggleModal();
  };
  const clearForm = () => {
    reset({
      name: '',
    });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { name } = getValues();

    const variables: any = {
      name,
      data: dataList,
      questionnaireId: selectedQuestionnaire._id,
    };

    onSubmit({ variables });
    clearForm();
  };

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={{ content: { ...customModal.content, width: '70%' } }}
      >
        <div className='o2t-modal pl-4 pr-4 pt-4 pb-3'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-question-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <label>
                  Configuration name <span className='mandatory'>*</span>
                </label>
                <input {...register('name', { required: true })} />
                {errors.name && (
                  <span className='error-msg'>
                    Configuration name is required.
                  </span>
                )}
              </div>
              <div className='alert-wrapper'>
                {/* {error && <p>{error.message}</p>} */}
              </div>
              <div className='form-group '>
                <label>
                  Select a questionnaire <span className='mandatory'>*</span>
                </label>

                <Controller
                  control={control}
                  name='questionnaire'
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={questionnaireList}
                      value={value}
                      onMenuScrollToBottom={onScroll}
                      onChange={(val) => {
                        return onChange(val);
                      }}
                      components={animatedComponents}
                    />
                  )}
                />
                {errors.product && (
                  <span className='error-msg'>Questionnaire is required.</span>
                )}
              </div>
              <div
                className='form-group'
                style={{
                  maxHeight: '20rem',
                  overflow: 'scroll',
                }}
              >
                <label>Questions list</label>

                {questionsList?.map((question: any, index: number) => {
                  return (
                    <div
                      key={question._id}
                      style={{
                        display: 'flex',
                        gap: '2rem',
                        marginBottom: '1rem',
                      }}
                    >
                      <label
                        style={{
                          width: '30%',
                          paddingTop: '1rem',
                          fontFamily: 'Roboto-regular',
                        }}
                      >
                        {question.label}
                      </label>
                      <Controller
                        control={control}
                        name={`label.${index}`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <Select
                            options={options}
                            value={value}
                            onChange={(val) => {
                              const existingDataIndex = dataList?.findIndex(
                                (data: any) => data.questionId === question._id
                              );
                              existingDataIndex === -1
                                ? setDataList([
                                    ...dataList,
                                    {
                                      questionId: question._id,
                                      value: val.value,
                                    },
                                  ])
                                : setDataList(
                                    dataList.map((data: any, index: number) => {
                                      if (index === existingDataIndex) {
                                        return {
                                          questionId: question._id,
                                          value: val.value,
                                        };
                                      }
                                      return data;
                                    })
                                  );
                              return onChange(val);
                            }}
                            components={animatedComponents}
                          />
                        )}
                      />
                    </div>
                  );
                })}
              </div>

              <div className='o2t-modal__footer'>
                <button
                  onClick={closeModal}
                  //   disabled={isLoading}
                  className='link-button mr-2'
                >
                  Cancel
                </button>
                <button
                  type='submit'
                  // disabled={isLoading}
                >
                  {!isEdit ? 'Add configuration' : 'Save'}
                  <span>
                    <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEditAnalyitcsConfigModal;
