import React, { useEffect, useState } from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { IPage } from '../Interfaces';
import Loading from '../Components/Basic/Loading';
import AccessControl from '../Components/AccessControl';
import SSOUserRedirectModal from 'src/Components/SettingsModule/SSOUserRedirectModal';
import { connect } from 'react-redux';
import { Role, Routes } from 'src/Utils/types';
import { useHistory } from 'react-router-dom';

const Dashboard: React.FunctionComponent<IPage> = (props: any) => {
  const { user } = props;
  const [openRedirectWarningModal, setOpenRedirectWarningModal] =
    useState(false);
  const history = useHistory();

  const showRedirectToLinkAccountPageDialog = (user: any) => {
    const identityProvider = user?.user_id?.split('|')?.[0];
    return (
      user &&
      user?.roles?.length === 0 &&
      user?.permissions?.length === 0 &&
      identityProvider !== 'auth0'
    );
  };

  useEffect(() => {
    if (
      user.roles?.length === 1 &&
      user?.roles?.indexOf(Role.INITIATOR) !== -1
    ) {
      history.push(Routes.LINK_DEVICE_VIEW);
    } else {
      const showRedirectDialog = showRedirectToLinkAccountPageDialog(user);
      setOpenRedirectWarningModal(showRedirectDialog);
    }
  }, [history, user]);

  const toggleRedirectWarningModal = () => {
    setOpenRedirectWarningModal(!openRedirectWarningModal);
  };

  const onRedirect = () => {
    toggleRedirectWarningModal();
    history.push(`${Routes.PROFILE_VIEW}#accounts`);
  };

  return (
    <>
      <AccessControl allowedPermissions={['view:dashboard_page']}>
        <div className='grid-wrapper__panel pt-4'>
          <h1 className='mb-5'>Dashboard</h1>
        </div>
      </AccessControl>

      {openRedirectWarningModal && (
        <SSOUserRedirectModal
          modalIsOpen={openRedirectWarningModal}
          modalTitle={'Redirect Warning'}
          onRedirect={onRedirect}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps)(
  withAuthenticationRequired(Dashboard, {
    onRedirecting: () => <Loading />,
  })
);
