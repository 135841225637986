import { connect } from 'react-redux';

const Feature = (props: any) => {
  const { name, children, featureFlags: features } = props;

  if (features.hasOwnProperty(name)) {
    const feature = features[name];

    if (feature && feature.isActive) {
      return children;
    }
  }
  return null;
};

const mapStateToProps = (state: any) => {
  return {
    featureFlags: state.featureFlags,
  };
};

export default connect(mapStateToProps)(Feature);
