import { ActionType } from '../Actions/actionTypes';

export const licensePaginationDataReducer = (state = {}, action: any) => {
  if (action.type === ActionType.GET_LICENSE_DATA) {
    return { ...state, ...action.payload };
  }
  return state;
};

export const licenseToastReducer = (state: any = null, action: any) => {
  if (action.type === ActionType.DISPLAY_TOAST) {
    return action.payload;
  }
  return state;
};

export const licenseReducer = (state: any = null, action: any) => {
  if (action.type === ActionType.GET_LICENSE) {
    return action.payload;
  }
  return state;
};
