import { useLazyQuery, useMutation } from '@apollo/client';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { connect } from 'react-redux';
import Select from 'react-select';
import Loading from 'src/Components/Basic/Loading';
import { useToast } from 'src/Components/Basic/Toastify';
import { IPage } from 'src/Interfaces';
import { GET_PAGINATED_LICENSES } from 'src/Query/licenses.query';
import { GET_PAGINATED_QUESTIONNAIRES } from 'src/Query/questionnaires.query';
import {
  GET_PAGINATED_SHARING_METHODS,
  LINK_DEVICE,
} from 'src/Query/sharingMethods.query';
import { setLicenseDataToStore } from 'src/States/Actions/licenseAction';
import { setQuestionnaireDataToStore } from 'src/States/Actions/questionnaireAction';
import { setSharingMethodDataToStore } from 'src/States/Actions/sharingMethodAction';
import { LinkTypes, ToastTypes } from 'src/Utils/types';

const LinkDevice: React.FunctionComponent<IPage> = (props: any) => {
  const { showToast } = useToast();

  const { register, handleSubmit, control, watch, setValue, reset } =
    useForm<any>();

  const licenseWatch = watch('license');
  const questionnaireWatch = watch('questionnaire');

  const [licenses, setLicenses] = useState<any>([]);
  const [questionnaires, setQuestionnaires] = useState<any>(null);
  const [sharingMethods, setSharingMethods] = useState<any>(null);

  const [getLicenses] = useLazyQuery(GET_PAGINATED_LICENSES, {
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        props.setLicenseDataToStore(completedData.licenses);
      }
    },
  });

  const [getQuestionnaires] = useLazyQuery(GET_PAGINATED_QUESTIONNAIRES, {
    errorPolicy: 'all',
    onCompleted: (completedData: any) => {
      if (completedData) {
        props.setQuestionnaireDataToStore(completedData.questionnaires);
      }
    },
  });

  const [getSharingMethods] = useLazyQuery(GET_PAGINATED_SHARING_METHODS, {
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        props.setSharingMethodDataToStore(completedData.sharingMethods);
      }
    },
  });

  const [linkDevice] = useMutation(LINK_DEVICE, {
    errorPolicy: 'none',
    onCompleted: () => {
      showToast('Device linked successfully');
      reset();
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const resetQuestionnairesField = useCallback(() => {
    setQuestionnaires(null);
    setValue('questionnaire', null);
  }, [setQuestionnaires, setValue]);

  const resetSharingMethodField = useCallback(() => {
    setSharingMethods(null);
    setValue('sharingMethod', null);
  }, [setSharingMethods, setValue]);

  useEffect(() => {
    getLicenses({
      variables: {
        limit: 10,
        page: 1,
      },
    });
  }, [getLicenses]);

  useEffect(() => {
    if (licenseWatch) {
      resetQuestionnairesField();
      getQuestionnaires({
        variables: {
          limit: 10,
          page: 1,
          licenseId: licenseWatch.value,
        },
      });
    }
  }, [getQuestionnaires, resetQuestionnairesField, licenseWatch]);

  useEffect(() => {
    if (questionnaireWatch) {
      resetSharingMethodField();
      getSharingMethods({
        variables: {
          limit: 10,
          page: 1,
          questionnaireId: questionnaireWatch.value,
        },
      });
    }
  }, [getSharingMethods, resetSharingMethodField, questionnaireWatch]);

  useEffect(() => {
    const licenseList = props?.licenses?.map((license: any) => {
      return {
        value: license._id,
        label: license.name,
      };
    });
    setLicenses(licenseList);
  }, [props.licenses]);

  useEffect(() => {
    let questionnairesList = null;
    resetQuestionnairesField();
    if (props?.questionnaires?.[0]?.licenseId === licenseWatch?.value) {
      questionnairesList = props?.questionnaires?.map((questionnaire: any) => {
        return {
          value: questionnaire._id,
          label: questionnaire.name,
        };
      });
    }
    setQuestionnaires(questionnairesList);
  }, [props.questionnaires, resetQuestionnairesField, licenseWatch]);

  useEffect(() => {
    let sharingMethodsList = null;
    resetSharingMethodField();
    if (
      props?.sharingMethods?.[0]?.questionnaireId === questionnaireWatch?.value
    ) {
      sharingMethodsList = props?.sharingMethods
        ?.filter((sharingMethod: any) => sharingMethod.type === LinkTypes.KIOSK)
        ?.map((sharingMethod: any) => {
          return {
            value: sharingMethod._id,
            label: sharingMethod.name,
          };
        });
    }
    setSharingMethods(sharingMethodsList);
  }, [props.sharingMethods, resetSharingMethodField, questionnaireWatch]);

  const onSubmit = (formData: any) => {
    const deviceId = sessionStorage.getItem('deviceId');
    if (deviceId) {
      const variables = {
        name: formData.name,
        licenseId: formData.license.value,
        questionnaireId: formData.questionnaire.value,
        sharingMethodId: formData.sharingMethod.value,
        deviceId,
      };
      linkDevice({ variables });
    } else {
      showToast('Device could not be found', ToastTypes.ERROR);
    }
  };

  return (
    <div className='link-page'>
      <div className='link-container'>
        <div className='link-container__header'>
          <h2 className='o2t-modal__header-title'>Link Device</h2>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className='form-group'>
            <div
              style={{
                marginTop: '2rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label style={{ alignSelf: 'center', width: '30%' }}>
                Location name
              </label>
              <div style={{ marginLeft: '1rem', width: '50%' }}>
                <input {...register('name', { required: true })} />
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div
              style={{
                marginTop: '2rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label style={{ alignSelf: 'center', width: '30%' }}>
                Select license
              </label>
              <Controller
                control={control}
                name='license'
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ marginLeft: '1rem', width: '50%' }}>
                    <Select
                      options={licenses}
                      onChange={onChange}
                      value={value}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className='form-group'>
            <div
              style={{
                marginTop: '2rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label style={{ alignSelf: 'center', width: '30%' }}>
                Select questionnaire
              </label>
              <Controller
                control={control}
                name='questionnaire'
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ marginLeft: '1rem', width: '50%' }}>
                    <Select
                      isDisabled={!licenseWatch || !questionnaires}
                      options={questionnaires}
                      onChange={onChange}
                      value={value}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className='form-group'>
            <div
              style={{
                marginTop: '2rem',
                display: 'flex',
                flexDirection: 'row',
              }}
            >
              <label style={{ alignSelf: 'center', width: '30%' }}>
                Select sharing method
              </label>
              <Controller
                control={control}
                name='sharingMethod'
                rules={{ required: false }}
                render={({ field: { onChange, value } }) => (
                  <div style={{ marginLeft: '1rem', width: '50%' }}>
                    <Select
                      isDisabled={!questionnaireWatch || !sharingMethods}
                      options={sharingMethods}
                      onChange={onChange}
                      value={value}
                    />
                  </div>
                )}
              />
            </div>
          </div>
          <div className='link-container__footer'>
            <button>Link Device</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    licenses: state.licenses.results,
    questionnaires: state.questionnaires.results,
    sharingMethods: state.sharingMethods.results,
  };
};

export default connect(mapStateToProps, {
  setLicenseDataToStore,
  setQuestionnaireDataToStore,
  setSharingMethodDataToStore,
})(
  withAuthenticationRequired(LinkDevice, {
    onRedirecting: () => <Loading />,
  })
);
