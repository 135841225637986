import { QuestionTypes } from 'src/Utils/types';
import ChoiceQuestionView from './QuestionTranslationViews/ChoiceQuestionView';
import CustomPageQuestionView from './QuestionTranslationViews/CustomPageQuestionView';
import MatrixQuestionView from './QuestionTranslationViews/MatrixQuestionView';
import OpenQuestionView from './QuestionTranslationViews/OpenQuestionView';
import PageQuestionView from './QuestionTranslationViews/PageQuestionView';
import RatingQuestionView from './QuestionTranslationViews/RatingQuestionView';

const TranslateViewWrapper = (props: any) => {
  const { data } = props;
  const { question } = data;

  const getTranslateQuestionComponent: any = (question: any, data: any) => {
    if (question.type === QuestionTypes.CONDITIONAL) {
      return getTranslateQuestionComponent(
        question?.questions?.[0]?.followupQuestion,
        {
          ...data,
          question: question?.questions?.[0]?.followupQuestion,
          conditionalQuestion: question,
        }
      );
    }

    switch (question.type) {
      case QuestionTypes.LANDING:
      case QuestionTypes.FINAL:
      case QuestionTypes.INFO:
        return <PageQuestionView data={data} />;
      case QuestionTypes.RATING:
        return <RatingQuestionView data={data} />;
      case QuestionTypes.SINGLE_CHOICE:
      case QuestionTypes.MULTIPLE_CHOICE:
      case QuestionTypes.RANKING:
        return <ChoiceQuestionView data={data} />;
      case QuestionTypes.OPEN:
        return <OpenQuestionView data={data} />;
      case QuestionTypes.RATING_PAGE:
      case QuestionTypes.CHOICE_PAGE:
        return <MatrixQuestionView data={data} />;
      case QuestionTypes.CUSTOM_FINAL:
      case QuestionTypes.CUSTOM_LANDING:
        return <CustomPageQuestionView data={data} />;
    }
  };

  return <div>{getTranslateQuestionComponent(question, data)}</div>;
};

export default TranslateViewWrapper;
