import { gql } from '@apollo/client';

export const GET_PAGINATED_LICENSES = gql`
  query GetLicenses(
    $searchTerm: String
    $sortBy: LicenseSortingOption
    $sortOrder: SortOrder
    $limit: Int!
    $next: String
    $previous: String
  ) {
    licenses(
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
      limit: $limit
      next: $next
      previous: $previous
    ) {
      results {
        _id
        createdBy
        updatedBy
        createdAt
        updatedAt
        name
        expireAt
        isActive
        licenseType
        template
        maxUsers
        maxActiveQuestionnaires
        maxQuestionnaires
        maxSubQuestionnaires
        maxQuestions
        maxOpenQuestions
        maxOpinions
        maxExports
        dataRegion
        users
        emailLists
        isArchived
        productId
        product
      }
      previous
      hasPrevious
      next
      hasNext
      total
    }
  }
`;

export const ADD_LICENSE = gql`
  mutation AddLicense(
    $name: String!
    $expireAt: String!
    $isActive: Boolean!
    $licenseType: String!
    $template: String!
    $maxUsers: Int
    $maxActiveQuestionnaires: Int
    $maxQuestionnaires: Int
    $maxSubQuestionnaires: Int
    $maxQuestions: Int
    $maxOpenQuestions: Int
    $maxOpinions: Int
    $maxExports: Int
    $dataRegion: String!
    $emailLists: [String]
    $productId: ID
  ) {
    addLicense(
      name: $name
      expireAt: $expireAt
      isActive: $isActive
      licenseType: $licenseType
      template: $template
      maxUsers: $maxUsers
      maxActiveQuestionnaires: $maxActiveQuestionnaires
      maxQuestionnaires: $maxQuestionnaires
      maxSubQuestionnaires: $maxSubQuestionnaires
      maxQuestions: $maxQuestions
      maxOpenQuestions: $maxOpenQuestions
      maxOpinions: $maxOpinions
      maxExports: $maxExports
      dataRegion: $dataRegion
      emailLists: $emailLists
      productId: $productId
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      expireAt
      isActive
      licenseType
      template
      maxUsers
      maxActiveQuestionnaires
      maxQuestionnaires
      maxSubQuestionnaires
      maxQuestions
      maxOpenQuestions
      maxOpinions
      maxExports
      dataRegion
      users
      emailLists
      isArchived
      productId
    }
  }
`;

export const UPDATE_LICENSE = gql`
  mutation UpdateLicense(
    $licenseId: ID!
    $name: String!
    $expireAt: String!
    $isActive: Boolean!
    $maxUsers: Int
    $maxActiveQuestionnaires: Int
    $maxQuestionnaires: Int
    $maxSubQuestionnaires: Int
    $maxQuestions: Int
    $maxOpenQuestions: Int
    $maxOpinions: Int
    $maxExports: Int
    $users: [JSONObject]
    $emailLists: [String]
  ) {
    updateLicense(
      _id: $licenseId
      name: $name
      expireAt: $expireAt
      isActive: $isActive
      maxUsers: $maxUsers
      maxActiveQuestionnaires: $maxActiveQuestionnaires
      maxQuestionnaires: $maxQuestionnaires
      maxSubQuestionnaires: $maxSubQuestionnaires
      maxQuestions: $maxQuestions
      maxOpenQuestions: $maxOpenQuestions
      maxOpinions: $maxOpinions
      maxExports: $maxExports
      users: $users
      emailLists: $emailLists
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      expireAt
      isActive
      licenseType
      template
      maxUsers
      maxActiveQuestionnaires
      maxQuestionnaires
      maxSubQuestionnaires
      maxQuestions
      maxOpenQuestions
      maxOpinions
      maxExports
      dataRegion
      users
      emailLists
      isArchived
      productId
    }
  }
`;

export const DELETE_LICENSE = gql`
  mutation DeleteLicense($licenseId: ID!) {
    deleteLicense(_id: $licenseId)
  }
`;

export const GET_LICENSES_BY_IDS = gql`
  query GetLicensesByIds($ids: [ID]) {
    licensesByIds(ids: $ids) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      expireAt
      isActive
      licenseType
      template
      maxUsers
      maxActiveQuestionnaires
      maxQuestionnaires
      maxSubQuestionnaires
      maxQuestions
      maxOpenQuestions
      maxOpinions
      maxExports
      dataRegion
      users
      emailLists
      isArchived
      productId
    }
  }
`;
