import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setAccessTokenToStore } from './Actions/accessTokenAction';
import { setAllUsersToStore } from './Actions/usersAction';
import { RootState } from './Reducers';

export const useActions = () => {
  const dispatch = useDispatch();

  return bindActionCreators(
    {
      setAccessTokenToStore,
      setAllUsersToStore,
    },
    dispatch
  );
};

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
