import { useEffect, useRef, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const modules = {
  clipboard: {
    matchVisual: false,
  },
  toolbar: false,
};

type Props = {
  style?: any;
  value?: { html: string; text: string };
  onChange: (value: any) => void;
};

const allowedTags = ['DIV', 'A', 'IMG', 'U', 'I', 'B', 'P', 'BR'];

const HTMLEditor = (props: Props) => {
  const [value, setValue] = useState<any>(props.value);

  const quillRef = useRef<any>();

  useEffect(() => {
    const htmlRegex = /(<|&lt;)\/?(?!div|a|img|p|br|u|i)\w*\b(^(>|&gt;))*>/gi;

    const cleanedText = props.value?.text?.replaceAll(htmlRegex, '');

    setValue({ ...props.value, text: cleanedText });
  }, [props.value]);

  const handleChange = (html: any, delta: any, source: any, editor: any) => {
    if (source === 'user') {
      const text = editor.getText();
      const container = document.createElement('div');
      container.innerHTML = text;
      container.querySelectorAll('*').forEach((node) => {
        if (!allowedTags.includes(node.nodeName)) {
          node.parentNode?.replaceChild(
            document.createTextNode(node.textContent || ''),
            node
          );
        }
      });
      const pastedText = container.innerHTML;
      setValue({ html: pastedText, text: html });
      props.onChange({
        html: pastedText,
        text: html,
      });
    }
  };

  return (
    <ReactQuill
      ref={quillRef}
      theme='snow'
      value={value?.text}
      style={{ fontSize: '24px', height: '100%' }}
      onChange={handleChange}
      modules={modules}
    />
  );
};

export default HTMLEditor;
