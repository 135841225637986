import { faCheckCircle, faPassport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { setLicense } from '../../States/Actions/licenseAction';

const LicenseListPanel = (props: any) => {
  const [openPanel, setOpenPanel] = useState(false);
  const [licenses, setLicenses] = useState([]);

  useEffect(() => {
    setLicenses(props.licenses);
  }, [props]);

  return (
    <div className={'notification'} style={{ position: 'relative' }}>
      <div
        className={'notification__iconSection'}
        onClick={() => setOpenPanel(!openPanel)}
      >
        <FontAwesomeIcon icon={faPassport} />
      </div>

      {openPanel && (
        <div className={'notificationBar'}>
          {licenses?.map((license: any, index: number) => {
            return (
              <div
                key={license._id}
                className={'lineItems'}
                onClick={() => {
                  setOpenPanel(false);
                  props.setLicense(license);
                }}
              >
                {((!props.selectedLicense && index === 0) ||
                  license?._id === props.selectedLicense?._id) && (
                  <FontAwesomeIcon
                    icon={faCheckCircle}
                    style={{
                      marginRight: '0.5rem',
                      color: 'green',
                    }}
                  />
                )}
                {license.name}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    licenses: state.user?.licenses,
    selectedLicense: state.license,
  };
};

export default connect(mapStateToProps, { setLicense })(LicenseListPanel);
