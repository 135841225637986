import { gql } from '@apollo/client';

export const GET_USER_ROLES = gql`
  query GetUserRoles($user_id: String) {
    userRoles(user_id: $user_id) {
      id
      name
    }
  }
`;
