export enum Routes {
  LICENSES_VIEW = '/licenses',
  PROFILE_VIEW = '/profile',
  PRODUCT_VIEW = '/products',
  QUESTIONNAIRES_VIEW = '/questionnaires',
  HOME_VIEW = '/',
  LINK_DEVICE_VIEW = '/link',
}

export enum QuestionTypes {
  LANDING = 'LANDING',
  OPEN = 'OPEN',
  RATING = 'RATING',
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
  INFO = 'INFO',
  FINAL = 'FINAL',
  CONDITIONAL = 'CONDITIONAL',
  RATING_PAGE = 'RATING_PAGE',
  CHOICE_PAGE = 'CHOICES_PAGE',
  CUSTOM_LANDING = 'CUSTOM_LANDING',
  CUSTOM_FINAL = 'CUSTOM_FINAL',
  RANKING = 'RANKING',
  FORM = 'FORM',
}

export enum QuestionSetTypes {
  RATING = 'RATING',
  CHOICE = 'CHOICE',
  MATRIX = 'MATRIX',
  OPEN = 'OPEN',
  PAGE = 'PAGE',
  CONDITIONAL = 'CONDITIONAL',
  RANKING = 'RANKING',
  FORM = 'FORM',
}

export enum RatingQuestionTypes {
  ONE_TO_TEN = '1-10',
  ONE_TO_FIVE = '1-5',
  CSAT = 'CSAT',
  CES = 'CES',
  NPS = 'NPS',
  TNPS = 'TNPS',
}

export enum ChoiceQuestionTypes {
  SINGLE_CHOICE = 'SINGLE_CHOICE',
  MULTIPLE_CHOICE = 'MULTIPLE_CHOICE',
}

export enum MatrixQuestionTypes {
  RATING_PAGE = 'RATING_PAGE',
  CHOICE_PAGE = 'CHOICES_PAGE',
}

export enum OpenQuestionTypes {
  EMAIL = 'EMAIL',
  NUMBER = 'NUMBER',
  TEXT = 'TEXT',
  DATE = 'DATE',
}

export enum PageQuestionTypes {
  LANDING = 'LANDING',
  INFO = 'INFO',
  FINAL = 'FINAL',
  CUSTOM_LANDING = 'CUSTOM_LANDING',
  CUSTOM_FINAL = 'CUSTOM_FINAL',
}

export enum QuestionnaireStatus {
  CONCEPT = 'Concept',
  PUBLISHED = 'Published',
  ARCHIVED = 'Archived',
}

export enum LinkType {
  TEST = 'TEST',
  OPEN = 'OPEN',
  UNIQUE = 'UNIQUE',
}

export enum Role {
  NONE = 'none',
  ADMIN = 'admin',
  OWNER = 'owner',
  INITIATOR = 'initiator',
}

export enum ImageType {
  LOGO = 'logo',
  BACKGROUND = 'background',
}

export enum OpenQuestionKeyboardType {
  TEXT = 'Text',
  NUMBER = 'Number',
  EMAIL = 'Email',
  CUSTOM = 'Custom',
  DATE = 'Date',
}

export enum Regions {
  EU_WEST_1 = 'eu-west-1',
  EU_CENTRAL_1 = 'eu-central-1',
  AP_SOUTHEAST_1 = 'ap-southeast-1',
  AP_SOUthEAST_2 = 'ap-southeast-2',
}

export enum LinkTypes {
  OPEN_LINK = 'OPEN_LINK',
  UNIQUE_LINK = 'UNIQUE_LINK',
  PORTAL_LINK = 'PORTAL_LINK',
  ONE_SHOT_LINK = 'ONE_SHOT_LINK',
  KIOSK = 'KIOSK',
}

export enum LinkStatus {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum ToastTypes {
  SUCCESS = 'success',
  ERROR = 'error',
}

export type Language = {
  name: string;
  code: string;
  native?: string;
  countryCode?: string;
};

export type JSONData = {
  [key: string]: number | string | null;
};
