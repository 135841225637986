import DataTable, { createTheme } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faPlay } from '@fortawesome/free-solid-svg-icons';

import { TableTheme, customTable } from '../../Constants/ThemeO2T';
import Loading from '../Basic/Loading';
import { formatDate } from '../../Utils/util';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';

createTheme(
  'o2t-table',
  {
    text: {
      primary: TableTheme.tTextPrimary,
    },
    background: {
      default: TableTheme.tBg,
    },
    divider: {
      default: TableTheme.tRowBorder,
    },
  },
  'light'
);

const AnalyticsConfigGrid = (props: any) => {
  const { data } = props;
  const { next, previous, paginationDetails, onRowClick, onRunConfig } = data;
  const totalRows = data.total || null;

  // Table columns configuration
  const columns = [
    {
      name: 'Name',
      selector: (row: any) => (row.name ? row.name : 'Name'),
      sortable: true,
      width: '30%',
    },
    {
      name: 'Questionnaire',
      selector: (row: any) =>
        row.questionnaireName ? row.questionnaireName : '',
      sortable: true,
      width: '25%',
    },
    {
      name: 'Created at',
      selector: (row: any) =>
        row.createdAt ? formatDate(new Date(Number(row.createdAt))) : 'Date',
      sortable: true,
      width: '15%',
    },
    {
      name: '# of Questions',
      selector: (row: any) => (row.data ? row.data.length : 0),
      sortable: true,
      width: '15%',
    },
    {
      button: true,
      width: '20%',
      cell: (row: any) => (
        <div className='actions-col'>
          <button
            className='btn-theme'
            onClick={(event) => {
              event.preventDefault();
              onRunConfig(row);
            }}
          >
            <FontAwesomeIcon icon={faPlay} />
          </button>
          <span className='divider'>|</span>
          <button
            className='btn-theme'
            onClick={(event) => {
              event.preventDefault();
              onRowClick(row);
            }}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <span className='divider'>|</span>
          <button
            className='btn-delete'
            onClick={() => onAnalyticsConfigDeleteClick(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  const onAnalyticsConfigDeleteClick = (config: any) => {
    data.handleDeleteConfigSelect(config);
  };

  const onPageChange = (page: number) => {
    const pagination: any =
      paginationDetails.page < page ? { next } : { previous };

    data.handlePageChange({
      ...pagination,
      limit: paginationDetails.limit,
      page,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const onPerRowsChange = (perPage: number) => {
    data.handlePageChange({
      limit: perPage,
      page: 1,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  return (
    <>
      <AccessControl
        allowedPermissions={['list:licenses']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view licenses'
            }
          />
        )}
      >
        <DataTable
          columns={columns}
          data={data.analyticsConfigs}
          theme='o2t-table'
          customStyles={customTable}
          progressPending={data.loading}
          progressComponent={<Loading />}
          pagination={true}
          paginationServer={true}
          onSort={data.handleSortByColumn}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={onPerRowsChange}
          onChangePage={onPageChange}
        />
      </AccessControl>
    </>
  );
};

export default AnalyticsConfigGrid;
