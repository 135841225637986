import { ActionType } from './actionTypes';

export const setQuestionnaireDataToStore = (
  paginationData: any = {
    previous: null,
    next: null,
    hasPrevious: false,
    hasNext: false,
    total: 0,
    results: [],
  }
) => {
  return {
    type: ActionType.GET_QUESTIONNAIRE_DATA,
    payload: paginationData,
  };
};

export const setQuestionnaireToStore = (questionnaire: any = null) => {
  return {
    type: ActionType.GET_QUESTIONNAIRE,
    payload: questionnaire,
  };
};
