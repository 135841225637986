import { Fragment, useEffect, useState } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { customModal } from '../../../Constants/ThemeO2T';
import { LinkStatus } from 'src/Utils/types';

const DisableSharingMethodModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  error,
  modalData,
  onUpdate,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleUpdateSharingMethod = () => {
    onUpdate({
      variables: {
        surveyId: modalData.sharingMethod?._id,
        questionnaireId: modalData?.questionnaire?._id,
        setActive: !(modalData.sharingMethod?.status === LinkStatus.ACTIVE),
      },
    });
  };

  useEffect(() => {
    setIsOpen(modalIsOpen);
  }, [modalIsOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={customModal}
        contentLabel='Delete License Modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>
              {modalData.sharingMethod?.status === LinkStatus.ACTIVE
                ? 'Disable'
                : 'Enable'}{' '}
              sharing method
            </h2>
          </div>

          <div className='o2t-modal__container'>
            {modalData?.sharingMethod?.status === LinkStatus.ACTIVE ? (
              <Fragment>
                <p>
                  You are about to disable the sharing method
                  <strong> {modalData?.sharingMethod?.name}</strong>. Once
                  disabled users will not be able to access the questionnaire
                  through this method.
                </p>

                <p>
                  Are you sure that you want to disable this sharing method?
                </p>
              </Fragment>
            ) : (
              <Fragment>
                <p>
                  You are about to enable the sharing method
                  <strong> {modalData?.sharingMethod?.name}</strong>. Once
                  enabled link will be active for users for voting.
                </p>

                <p>Are you sure that you want to enable this sharing method?</p>
              </Fragment>
            )}
          </div>
          <div className='alert-wrapper'>{error && <p>{error.message}</p>}</div>
          <div className='o2t-modal__footer'>
            <button
              onClick={toggleModal}
              className='link-button mr-2'
              disabled={isLoading}
            >
              Cancel
            </button>
            <button onClick={handleUpdateSharingMethod} disabled={isLoading}>
              {modalData?.sharingMethod?.status === LinkStatus.ACTIVE
                ? 'Disable'
                : 'Enable'}
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DisableSharingMethodModal;
