export const licenseTypes = [
  {
    code: 'basic',
    label: 'Basic',
  },
  {
    code: 'enterprise',
    label: 'Enterprise',
  },
];

export const questionTypes = new Map<string, string>([
  ['LANDING', 'STATIC'],
  ['INFO', 'STATIC'],
  ['FINAL', 'STATIC'],
  ['CUSTOM_LANDING', 'STATIC'],
  ['CUSTOM_FINAL', 'STATIC'],
  ['JPG-FRAMES', 'ANIMATION'],
  ['OPEN', 'INPUT'],
  ['LOOKUP', 'INPUT'],
  ['AUTOCOMPLETE', 'INPUT'],
  ['UPLOAD', 'INPUT'],
  ['RATING_PAGE', 'MATRIX'],
  ['CHOICES_PAGE', 'MATRIX'],
  ['RATING', 'QUESTION'],
  ['SINGLE_CHOICE', 'QUESTION'],
  ['MULTIPLE_CHOICE', 'QUESTION'],
  ['CHOICES', 'QUESTION'],
  ['RANKING', 'QUESTION'],
  ['DRAGGING', 'QUESTION'],
  ['CONDITIONAL', 'QUESTION'],
  ['FORM', 'BUNDLE'],
  ['FILTER', 'BUNDLE'],
  ['PROJECT', 'BUNDLE'],
  ['TEMPLATE', 'BUNDLE'],
  ['GOTO-PAGE', 'ROUTING'],
  ['SWITCH-LOCATION', 'ROUTING'],
  ['BUTTONS', 'ROUTING'],
  ['LANGUAGE', 'ROUTING'],
]);
