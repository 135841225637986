import { Dispatch } from 'redux';
import { ActionType } from './actionTypes';
import { getAllUsers } from './index';

export const setAllUsersToStore = (allUsers: any = []) => {
  const formattedUsers = allUsers.reduce(
    (prev: any, current: any) => ({
      ...prev,
      [current._id]: current,
    }),
    {}
  );

  return (dispatch: Dispatch<getAllUsers>) => {
    dispatch({
      type: ActionType.GET_ALL_USERS,
      payload: formattedUsers,
    });
  };
};

export const setUserDataToStore = (
  paginationData: any = {
    length: 10,
    limit: 10,
    start: 0,
    total: null,
    users: [],
  }
) => {
  return {
    type: ActionType.GET_USER_DATA,
    payload: paginationData,
  };
};

export const setLoggedInUserToStore = (user: any = null) => {
  return {
    type: ActionType.GET_USER,
    payload: user,
  };
};

export const setAssignedLicenses = (licenses: any[] = []) => {
  return {
    type: ActionType.GET_USER_LICENSES,
    payload: licenses,
  };
};
