import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import jwtDecode from 'jwt-decode';
import { Router, Route, Switch, RouteComponentProps } from 'react-router-dom';
import Modal from 'react-modal';

import Layout from './Components/Layout/Layout';
import LoginScreen from './Components/LoginScreen';
import Routes from './Constants/routes';
import history from './Constants/history';
import Loading from './Components/Basic/Loading';

import { RootState } from './States';

import 'react-toastify/dist/ReactToastify.css';
import 'react-tabs/style/react-tabs.css';
import './Styles/App.scss';
import { useLazyQuery } from '@apollo/client';
import { GET_LICENSES_BY_IDS } from './Query/licenses.query';
import { connect, useSelector } from 'react-redux';
import { GET_USER } from './Query';
import {
  setAssignedLicenses,
  setLoggedInUserToStore,
} from './States/Actions/usersAction';
import { setLicense } from './States/Actions/licenseAction';
import { addFeatureFlags } from './States/Actions/featureAction';
import featureFlags from '../src/Config/feature.flags.json';

Modal.setAppElement('#root');

function App(props: any) {
  const { isAuthenticated, isLoading, error, user, getAccessTokenSilently } =
    useAuth0();
  const auth0Audience: string = process.env.REACT_APP_AUTH0_AUDIENCE || '';
  const { selectedLicense } = useSelector((state: RootState) => {
    return { selectedLicense: state.license };
  });

  useEffect(() => {
    props.addFeatureFlags(featureFlags);
  }, [props]);

  const [loadUser] = useLazyQuery(GET_USER, {
    variables: { userId: user?.sub },
    errorPolicy: 'ignore',
    onCompleted: async (data: any) => {
      const token = await getAccessTokenSilently();
      const decoded = jwtDecode<any>(token);
      const roles = decoded[auth0Audience + '_roles'];
      const user = { ...data.qmsuser };

      user.roles = roles;
      user.permissions = decoded.permissions;
      props.setLoggedInUserToStore(user);

      loadLicensesByIds({
        variables: {
          ids: user?.app_metadata?.licenses || [],
        },
      });
    },
  });

  const [loadLicensesByIds] = useLazyQuery(GET_LICENSES_BY_IDS, {
    errorPolicy: 'ignore',
    onCompleted: (completedData: any) => {
      if (!selectedLicense && completedData?.licensesByIds?.length) {
        props.setLicense(completedData?.licensesByIds[0]);
      }
      props.setAssignedLicenses(completedData?.licensesByIds);
    },
    onError: (error: any) => {
      console.error(error);
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      loadUser({ variables: { userId: user?.sub } });
    }
  }, [isAuthenticated, loadUser, user]);

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      {isAuthenticated && (
        <Router history={history}>
          <Layout>
            <Switch>
              {Routes.map((route, index) => {
                return (
                  <Route
                    key={index}
                    path={route.path}
                    exact={route.exact}
                    render={(props: RouteComponentProps<any>) => (
                      <route.component
                        name={route.name}
                        {...props}
                        {...route.props}
                      />
                    )}
                  />
                );
              })}
            </Switch>
          </Layout>
        </Router>
      )}
      {!isAuthenticated && <LoginScreen />}
    </>
  );
}

export default connect(null, {
  setAssignedLicenses,
  setLoggedInUserToStore,
  setLicense,
  addFeatureFlags,
})(App);
