import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ToastTypes } from 'src/Utils/types';

export const useToast = () => {
  const showToast = (message: string, type = ToastTypes.SUCCESS) => {
    if (type === ToastTypes.SUCCESS) {
      toast.success(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (type === ToastTypes.ERROR) {
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return { showToast };
};
