import { gql } from '@apollo/client';

export const GET_PAGINATED_ANALYTICS_CONFIGURATIONS = gql`
  query GetAnalyticsConfigurations(
    $licenseId: ID!
    $sortBy: AnalyticsConfigurationSortingOption
    $sortOrder: SortOrder
    $limit: Int!
    $next: String
    $previous: String
  ) {
    analyticsConfigurations(
      licenseId: $licenseId
      sortBy: $sortBy
      sortOrder: $sortOrder
      limit: $limit
      next: $next
      previous: $previous
    ) {
      results {
        _id
        name
        createdAt
        updatedAt
        licenseId
        questionnaireId
        questionnaireName
        data
      }
      previous
      hasPrevious
      next
      hasNext
      total
    }
  }
`;

export const ADD_ANALYTICS_CONFIGURATION = gql`
  mutation AddAnalyticsConfiguration(
    $name: String!
    $questionnaireId: ID!
    $licenseId: ID!
    $data: [JSONObject]
  ) {
    addAnalyticsConfiguration(
      name: $name
      questionnaireId: $questionnaireId
      licenseId: $licenseId
      data: $data
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      data
      questionnaireId
      licenseId
    }
  }
`;

export const RUN_ANALYTICS_CONFIGURATION = gql`
  query RunAnalyticsConfiguration($configId: ID!) {
    runAnalyticsConfiguration(configId: $configId) {
      config {
        _id
        name
        licenseId
        questionnaireId
        data
      }
      regressionData
    }
  }
`;

export const UPDATE_ANALYTICS_CONFIGURATION = gql`
  mutation UpdateAnalyticsConfiguration(
    $configId: ID!
    $name: String!
    $data: [JSONObject]
  ) {
    updateAnalyticsConfiguration(
      configId: $configId
      name: $name
      data: $data
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      data
      questionnaireId
      licenseId
    }
  }
`;

export const DELETE_ANALYTICS_CONFIGURATION = gql`
  mutation DeleteAnalyticsConfiguration($configId: ID!) {
    deleteAnalyticsConfiguration(configId: $configId)
  }
`;
