import { useEffect, useState } from 'react';
import ReactCountryFlag from 'react-country-flag';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Language, QuestionTypes } from 'src/Utils/types';
import ChoiceQuestionTranslateComponent from './QuestionTranslateComponents/ChoiceQuestionTranslateComponent';
import CustomPageQuestionTranslateComponent from './QuestionTranslateComponents/CustomPageQuestionTranslateComponent';
import MatrixQuestionTranslateComponent from './QuestionTranslateComponents/MatrixQuestionTranslateComponent';
import OpenQuestionTranslateComponent from './QuestionTranslateComponents/OpenQuestionTranslateComponent';
import PageQuestionTranslateComponent from './QuestionTranslateComponents/PageQuestionTranslateComponent';
import RatingQuestionTranslateComponent from './QuestionTranslateComponents/RatingQuestionTranslateComponent';

const TranslateComponentWrapper = (props: any) => {
  const { data } = props;
  const { question, questionnaire, choices, setChoices, answers, setAnswers } =
    data;

  const [supportedLanguages, setSupportedLanguages] = useState<Language[]>([]);
  const [selectedTab, setSelectedTab] = useState(0);

  useEffect(() => {
    const defaultLanguage = questionnaire.defaultLanguage;
    const supportedLanguages = questionnaire.supportedLanguages.filter(
      (language: Language) => language.code !== defaultLanguage.code
    );
    setSupportedLanguages(supportedLanguages);
  }, [questionnaire]);

  const getQuestionTranslateComponent: any = (
    question: any,
    questionnaire: any,
    languageCode: string
  ) => {
    if (question.type === QuestionTypes.CONDITIONAL) {
      return getQuestionTranslateComponent(
        question?.questions?.[0]?.followupQuestion,
        questionnaire,
        languageCode
      );
    }

    switch (question.type) {
      case QuestionTypes.LANDING:
      case QuestionTypes.FINAL:
      case QuestionTypes.INFO:
        return (
          <PageQuestionTranslateComponent
            data={{
              question,
              questionnaire,
              languageCode,
            }}
          />
        );
      case QuestionTypes.RATING:
        return (
          <RatingQuestionTranslateComponent
            data={{
              question,
              questionnaire,
              languageCode,
              choices,
              setChoices,
            }}
          />
        );
      case QuestionTypes.SINGLE_CHOICE:
      case QuestionTypes.MULTIPLE_CHOICE:
      case QuestionTypes.RANKING:
        return (
          <ChoiceQuestionTranslateComponent
            data={{
              question,
              questionnaire,
              languageCode,
              choices,
              setChoices,
            }}
          />
        );
      case QuestionTypes.OPEN:
        return (
          <OpenQuestionTranslateComponent
            data={{
              question,
              questionnaire,
              languageCode,
            }}
          />
        );
      case QuestionTypes.RATING_PAGE:
      case QuestionTypes.CHOICE_PAGE:
        return (
          <MatrixQuestionTranslateComponent
            data={{
              question,
              questionnaire,
              languageCode,
              choices,
              setChoices,
              answers,
              setAnswers,
            }}
          />
        );
      case QuestionTypes.CUSTOM_FINAL:
      case QuestionTypes.CUSTOM_LANDING:
        return (
          <CustomPageQuestionTranslateComponent
            data={{
              question,
              questionnaire,
              languageCode,
            }}
          />
        );
    }
  };

  return (
    <div>
      <div className='inner-tabs'>
        <div>
          <Tabs
            selectedIndex={selectedTab}
            onSelect={(index: number) => setSelectedTab(index)}
          >
            <TabList>
              {supportedLanguages?.map((language: any) => {
                return (
                  <Tab key={language.code}>
                    <ReactCountryFlag countryCode={language.countryCode} />{' '}
                    {language.code.toUpperCase()}
                  </Tab>
                );
              })}
            </TabList>

            {supportedLanguages?.map((language: any) => {
              return (
                <TabPanel key={language.code}>
                  <div className='q-translate-content__editor'>
                    {getQuestionTranslateComponent(
                      question,
                      questionnaire,
                      language.code
                    )}
                  </div>
                </TabPanel>
              );
            })}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default TranslateComponentWrapper;
