import { ActionType } from '../Actions/actionTypes';

export const questionnairePaginationDataReducer = (state = {}, action: any) => {
  if (action.type === ActionType.GET_QUESTIONNAIRE_DATA) {
    return { ...state, ...action.payload };
  }
  return state;
};

export const questionnaireReducer = (state = {}, action: any) => {
  if (action.type === ActionType.GET_QUESTIONNAIRE) {
    return action.payload;
  }
  return state;
};
