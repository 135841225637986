import { gql } from '@apollo/client';

export const GET_PAGINATED_PRODUCTS = gql`
  query GetProducts(
    $searchTerm: String
    $sortBy: ProductSortingOption
    $sortOrder: SortOrder
    $limit: Int!
    $next: String
    $previous: String
  ) {
    products(
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
      limit: $limit
      next: $next
      previous: $previous
    ) {
      results {
        _id
        createdBy
        updatedBy
        createdAt
        updatedAt
        name
        associatedLicenseIds
      }
      previous
      hasPrevious
      next
      hasNext
      total
    }
  }
`;

export const ADD_PRODUCT = gql`
  mutation AddProduct($name: String!) {
    addProduct(name: $name) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      associatedLicenseIds
    }
  }
`;

export const UPDATE_PRODUCT = gql`
  mutation UpdateProduct($productId: ID!, $name: String!) {
    updateProduct(_id: $productId, name: $name) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      associatedLicenseIds
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation DeleteProduct($productId: ID!) {
    deleteProduct(_id: $productId)
  }
`;

export const GET_PRODUCTS_BY_IDS = gql`
  query GetProductsByIds($ids: [ID]) {
    productsByIds(ids: $ids) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      associatedLicenseIds
    }
  }
`;
