import DataTable, { createTheme } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

import { TableTheme, customTable } from '../../Constants/ThemeO2T';
import Loading from '../Basic/Loading';

createTheme(
  'o2t-table',
  {
    text: {
      primary: TableTheme.tTextPrimary,
    },
    background: {
      default: TableTheme.tBg,
    },
    divider: {
      default: TableTheme.tRowBorder,
    },
  },
  'light'
);

const StyleTemplatesGrid = (props: any) => {
  const { data } = props;
  const { next, previous, paginationDetails } = data;

  const totalRows = data.total || null;

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => (row.name ? row.name : 'Name'),
      sortable: true,
    },
    {
      name: 'Type',
      selector: (row: any) => row.styleTemplateType,
      sortable: true,
    },
    {
      button: true,
      cell: (row: any) => (
        <div className='actions-col'>
          <button
            className='btn-delete'
            onClick={() => onStyleTemplateDeleteClick(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  const onStyleTemplateDeleteClick = (styleTemplate: any) => {
    data.handleDeleteStyleTemplateSelect(styleTemplate);
  };

  const onPageChange = (page: number) => {
    const pagination: any =
      paginationDetails.page < page ? { next } : { previous };

    data.handlePageChange({
      ...pagination,
      limit: paginationDetails.limit,
      page,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const onPerRowsChange = (perPage: number) => {
    data.handlePageChange({
      limit: perPage,
      page: 1,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  return (
    <>
      <DataTable
        columns={columns}
        data={data.styleTemplates || []}
        theme='o2t-table'
        customStyles={customTable}
        progressPending={data.loading}
        progressComponent={<Loading />}
        pagination={true}
        paginationServer={true}
        onSort={data.handleSortByColumn}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={onPerRowsChange}
        onChangePage={onPageChange}
        onRowClicked={data.handleStyleTemplateSelect}
        conditionalRowStyles={[
          {
            when: (row) => row,
            style: {
              cursor: 'pointer',
            },
          },
        ]}
      />
    </>
  );
};

export default StyleTemplatesGrid;
