import DataTable, { createTheme } from 'react-data-table-component';

import { TableTheme, customTable } from '../../Constants/ThemeO2T';
import Loading from '../Basic/Loading';
import { formatDate } from '../../Utils/util';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';

createTheme(
  'o2t-table',
  {
    text: {
      primary: TableTheme.tTextPrimary,
    },
    background: {
      default: TableTheme.tBg,
    },
    divider: {
      default: TableTheme.tRowBorder,
    },
  },
  'light'
);

const SurveyVersionsGrid = (props: any) => {
  const { data } = props;
  const { next, previous, paginationDetails } = data;

  const totalRows = data.total || null;

  // Table columns configuration
  const columns = [
    {
      name: 'Published at',
      selector: (row: any) =>
        row.createdAt ? formatDate(new Date(Number(row.createdAt))) : 'N/A',
      sortable: true,
    },
    {
      name: 'Version',
      selector: (row: any) =>
        row.survey?.version ? row.survey?.version : 'N/A',
      sortable: true,
    },
    {
      name: '# of Questions ',
      selector: (row: any) =>
        row.survey?.questionnaire?.questionCount
          ? row.survey?.questionnaire?.questionCount
          : '0',
      sortable: true,
    },
  ];

  const onPageChange = (page: number) => {
    const pagination: any =
      paginationDetails.page < page ? { next } : { previous };

    data.handlePageChange({
      ...pagination,
      limit: paginationDetails.limit,
      page,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const onPerRowsChange = (perPage: number) => {
    data.handlePageChange({
      limit: perPage,
      page: 1,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  return (
    <>
      <AccessControl
        allowedPermissions={['list:licenses']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view licenses'
            }
          />
        )}
      >
        <DataTable
          columns={columns}
          data={data.surveyVersions}
          theme='o2t-table'
          customStyles={customTable}
          progressPending={data.loading}
          progressComponent={<Loading />}
          pagination={true}
          paginationServer={true}
          onSort={data.handleSortByColumn}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={onPerRowsChange}
          onChangePage={onPageChange}
        />
      </AccessControl>
    </>
  );
};

export default SurveyVersionsGrid;
