import React, { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

type Props = {
  style?: any;
  placeholder?: string;
  value: string;
  disabled: boolean;
  onChange: (value: string) => void;
  onFocus?: (event: any) => void;
};

const QuestionTextInput = (props: Props) => {
  const [value, setValue] = useState(props.value);

  const toolbarOptions = [['bold', 'italic', 'underline']];

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  const handleValueUpdate = (value: string) => {
    setValue(value);
    props.onChange(value);
  };

  return (
    <ReactQuill
      style={{ fontSize: '24px' }}
      theme='bubble'
      placeholder={props.placeholder}
      value={value}
      onChange={(event) => handleValueUpdate(event)}
      modules={{ toolbar: toolbarOptions }}
      onFocus={props.onFocus}
    />
  );
};

export default QuestionTextInput;
