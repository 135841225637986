import DataTable, { createTheme } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEye, faBars } from '@fortawesome/free-solid-svg-icons';

import { TableTheme, customTable } from '../../Constants/ThemeO2T';
import Loading from '../Basic/Loading';
import { formatDate } from '../../Utils/util';
// import AccessControl from '../AccessControl';

createTheme(
  'o2t-table',
  {
    text: {
      primary: TableTheme.tTextPrimary,
    },
    background: {
      default: TableTheme.tBg,
    },
    divider: {
      default: TableTheme.tRowBorder,
    },
  },
  'light'
);

const LinkDataSetsGrid = (props: any) => {
  const { data } = props;
  const { next, previous, paginationDetails } = data;
  const totalRows = data.total || null;

  // Table columns configuration
  const columns = [
    {
      name: 'Name',
      selector: (row: any) => (row.name ? row.name : 'Name'),
      sortable: true,
    },
    {
      name: 'Data Type',
      selector: (row: any) => (row.dataType ? row.dataType : 'DataType'),
      sortable: true,
    },
    {
      name: 'Created at',
      selector: (row: any) =>
        row.createdAt ? formatDate(new Date(Number(row.createdAt))) : 'Date',
      sortable: true,
    },
    {
      name: 'Updated at',
      selector: (row: any) =>
        row.createdAt ? formatDate(new Date(Number(row.updatedAt))) : 'Date',
      sortable: true,
    },
    {
      button: true,
      cell: (row: any) => (
        <div className='actions-col link-data-sets'>
          <button
            className='fa-theme'
            onClick={() => data.handleLinkDataSetSelect(row)}
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <span className='divider'>|</span>
          <button
            className='btn-theme'
            onClick={() => data.handleLinkDataSetSelect(row)}
          >
            <FontAwesomeIcon icon={faEye} />
          </button>
          <span className='divider'>|</span>
          <button
            className='btn-delete'
            onClick={() => onLinkDataSetDeleteClick(row)}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        </div>
      ),
    },
  ];

  const onLinkDataSetDeleteClick = (linkDataSet: any) => {
    data.handleDeleteLinkDataSetSelect(linkDataSet);
  };

  const onPageChange = (page: number) => {
    const pagination: any =
      paginationDetails.page < page ? { next } : { previous };

    data.handlePageChange({
      ...pagination,
      limit: paginationDetails.limit,
      page,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const onPerRowsChange = (perPage: number) => {
    data.handlePageChange({
      limit: perPage,
      page: 1,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  return (
    <>
      {/* <AccessControl
        allowedPermissions={['list:linkDataSets']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view linkDataSets'
            }
          />
        )}
      > */}
      <DataTable
        columns={columns}
        data={data.linkDataSets}
        theme='o2t-table'
        customStyles={customTable}
        progressPending={data.loading}
        progressComponent={<Loading />}
        pagination={true}
        paginationServer={true}
        onSort={data.handleSortByColumn}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={onPerRowsChange}
        onChangePage={onPageChange}
      />
      {/* </AccessControl> */}
    </>
  );
};

export default LinkDataSetsGrid;
