import Select from 'react-select';
import ToggleSwitch from '../Basic/ToggleSwitch';
import makeAnimated from 'react-select/animated';

const animatedComponents = makeAnimated();

const TopSearch = (props: any) => {
  const {
    showArchived,
    value,
    setShowArchived,
    onChange,
    itemList,
    isProductModule,
    onScroll,
  } = props;

  const handleSwitch = (nextChecked: boolean) => {
    setShowArchived(nextChecked);
  };

  return (
    <div className='row top-search m-0'>
      <div className='col status-wrapper'>
        <label>{isProductModule ? 'Product' : 'Status'}</label>
        <div className='select-wrapper'>
          <Select
            options={itemList}
            value={value?.value}
            onChange={(event: any) => {
              if (isProductModule) {
                onChange(
                  event.value !== itemList?.[0].label ? event.value : null
                );
              } else {
                onChange(event.value);
              }
            }}
            onMenuScrollToBottom={(event) => {
              if (isProductModule) onScroll(event);
            }}
            components={animatedComponents}
          />
        </div>
      </div>
      {!isProductModule && (
        <div className='col toggle-wrapper'>
          <ToggleSwitch handleSwitch={handleSwitch} checked={showArchived} />
          <label>Show archived questionnaires</label>
        </div>
      )}
    </div>
  );
};

export default TopSearch;
