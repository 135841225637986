import { useEffect, useState } from 'react';
import Modal from 'react-modal';

import { customModal } from '../../../Constants/ThemeO2T';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronRight,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons';

const DeviceListModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalData,
  error,
  isLoading,
  onSubmit,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [devicesList, setDevicesList] = useState(
    modalData?.sharingMethod?.data || []
  );

  useEffect(() => {
    setIsOpen(modalIsOpen);
  }, [modalIsOpen]);

  const handleUpdateDeviceListStatus = () => {
    onSubmit({
      variables: {
        sharingMethodId: modalData?.sharingMethod?._id,
        deviceList: devicesList,
      },
    });
  };

  const handleDeviceStatusChange = (locationId: string) => {
    const updatedDevicesList = devicesList.map((device: any) => {
      if (device.locationId === locationId) {
        return {
          ...device,
          isActive: !device.isActive,
        };
      }
      return device;
    });
    setDevicesList(updatedDevicesList);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={{
          ...customModal,
          content: { ...customModal.content, minHeight: 'unset' },
        }}
        contentLabel='Device List'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>Device List</h2>
          </div>
          <div className='o2t-modal__container'>
            {devicesList?.map((device: any) => (
              <div key={device.locationId} className='device-item'>
                <p className='device-label'>{device.name}</p>
                <div className='device-btn-wrapper'>
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      handleDeviceStatusChange(device.locationId);
                    }}
                  >
                    <FontAwesomeIcon
                      icon={device.isActive ? faToggleOn : faToggleOff}
                    />
                  </button>
                </div>
              </div>
            ))}
          </div>
          <div className='alert-wrapper'>{error && <p>{error.message}</p>}</div>
          <div className='o2t-modal__footer'>
            <button onClick={toggleModal} className='mr-2' disabled={isLoading}>
              Cancel
            </button>
            <button
              onClick={() => handleUpdateDeviceListStatus()}
              disabled={isLoading}
            >
              Save
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DeviceListModal;
