import { ChromePicker } from 'react-color';

const ColorPicker = ({ onClose, onColorChange, color }: any) => {
  const handleClose = () => {
    onClose(false);
  };

  const rgbaToHex = (rgba: any) => {
    const outParts = [
      rgba.r.toString(16),
      rgba.g.toString(16),
      rgba.b.toString(16),
      Math.round(rgba.a * 255)
        .toString(16)
        .substring(0, 2),
    ];

    outParts.forEach((part, i) => {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    });

    return '#' + outParts.join('');
  };

  return (
    <div className='color-picker-container'>
      <div className='color-picker' onClick={handleClose} />
      <ChromePicker
        styles={{
          default: {
            picker: { position: 'fixed' },
          },
        }}
        color={color}
        onChange={(event) => {
          const hex = rgbaToHex(event.rgb);
          onColorChange(hex);
        }}
      />
    </div>
  );
};

export default ColorPicker;
