import React, { useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { customModal } from '../../Constants/ThemeO2T';

const AddEditProductModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  isEdit,
  modalData,
  onSubmit,
  error,
  isLoading,
}) => {
  const modalTitle = isEdit ? 'Edit Product' : 'Add product';
  const { register, reset, getValues } = useForm<any>();
  const closeModal = () => {
    toggleModal();
  };
  const clearForm = () => {
    reset({
      name: '',
    });
  };

  const getParams = ({ name }: any) => {
    const variables: any = {
      name,
    };

    if (isEdit) {
      variables.productId = modalData.selectedProduct._id;
    }

    return variables;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    const { name } = getValues();

    const variables: any = getParams({
      name,
    });

    onSubmit({ variables });
    clearForm();
  };

  useEffect(() => {
    reset({
      name: modalData?.selectedProduct?.name,
    });
  }, [modalData, reset]);

  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel='Add Product Modal'
        style={{
          content: {
            ...customModal.content,
            width: '25%',
            height: 'fit-content',
            maxHeight: '90%',
            overflow: 'hidden',
          },
        }}
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <form onSubmit={handleSubmit}>
              <div className='form-group'>
                <div className='product-add-edit'>
                  <div>
                    <div className='design-content-action-info'>
                      <div className='product-input-field'>
                        <div>Name</div>
                        <div style={{ marginTop: '1rem' }}>
                          <input
                            className='form-field-full-length'
                            type='text'
                            {...register('name', {
                              required: true,
                            })}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className='alert-wrapper'>
                  {error && <p>{error.message}</p>}
                </div>

                <div
                  className='o2t-modal__footer'
                  style={{ paddingTop: '1rem' }}
                >
                  <button
                    onClick={closeModal}
                    className='link-button mr-2'
                    disabled={isLoading}
                  >
                    Cancel
                  </button>
                  <button
                    type='submit'
                    data-testid='product-save-btn'
                    disabled={isLoading}
                  >
                    {isEdit ? 'Save' : 'Add Product'}
                    <span>
                      <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                    </span>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AddEditProductModal;
