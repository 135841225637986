import React from 'react';
import Switch from 'react-switch';
import { ISwitchProps } from '../../Interfaces';

const ToggleSwitch: React.FC<ISwitchProps> = ({
  handleSwitch,
  checked,
  width,
  height,
  disabled,
}) => {
  return (
    <>
      <Switch
        width={width}
        height={height}
        onChange={handleSwitch}
        checked={checked}
        checkedIcon={false}
        uncheckedIcon={false}
        offColor='#fff'
        onColor='#fff'
        offHandleColor='#838383'
        onHandleColor='#4481BF'
        className={'react-switch ' + (checked ? 'checked' : '')}
        disabled={disabled}
      />
    </>
  );
};

export default ToggleSwitch;
