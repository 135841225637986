import useCollapse from 'react-collapsed';

const LicenseSettings = ({ register }: any) => {
  const { getCollapseProps, getToggleProps } = useCollapse();

  return (
    <div>
      <div className='page-header section-header'>
        <div className='page-header__title'>
          <h2>Settings: </h2>
        </div>
        <div className='page-header__actions form-header'>
          <button {...getToggleProps()}>Settings</button>
        </div>
      </div>
      <div {...getCollapseProps()}>
        <div className='form-wrapper'>
          <div className='row'>
            <div className='form-group col'>
              <label>Max Users</label>
              <input
                {...register('maxUsers', {
                  valueAsNumber: true,
                })}
                type='number'
                min={0}
                max={100}
              />
            </div>
            <div className='form-group col'>
              <label>Max Questionnaires</label>
              <input
                {...register('maxQuestionnaires', {
                  valueAsNumber: true,
                })}
                type='number'
                min={0}
                max={100}
              />
            </div>
            <div className='form-group col'>
              <label>Max Active Questionnaires</label>
              <input
                {...register('maxActiveQuestionnaires', {
                  valueAsNumber: true,
                })}
                type='number'
                min={0}
                max={100}
              />
            </div>
          </div>
          <div className='row'>
            <div className='form-group col'>
              <label>Max Sub Questionnaires</label>
              <input
                {...register('maxSubQuestionnaires', {
                  valueAsNumber: true,
                })}
                type='number'
                min={0}
                max={100}
              />
            </div>
            <div className='form-group col'>
              <label>Max Questions</label>
              <input
                {...register('maxQuestions', {
                  valueAsNumber: true,
                })}
                type='number'
                min={0}
                max={100}
              />
            </div>
            <div className='form-group col'>
              <label>Max Open Questions</label>
              <input
                {...register('maxOpenQuestions', {
                  valueAsNumber: true,
                })}
                type='number'
                min={0}
                max={100}
              />
            </div>
          </div>
          <div className='row'>
            <div className='form-group col'>
              <label>Max Opinions</label>
              <input
                {...register('maxOpinions', {
                  valueAsNumber: true,
                })}
                type='number'
                min={0}
                max={100}
              />
            </div>
            <div className='form-group col'>
              <label>Max Exports</label>
              <input
                {...register('maxExports', {
                  valueAsNumber: true,
                })}
                type='number'
                min={0}
                max={100}
              />
            </div>
            <div className='form-group col' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LicenseSettings;
