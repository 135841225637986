export enum ActionType {
  GET_ACCESS_TOKEN = 'GET_ACCESS_TOKEN',
  GET_ALL_USERS = 'GET_ALL_USERS',
  GET_USER_DATA = 'GET_USER_DATA',
  GET_LICENSE_DATA = 'GET_LICENSE_DATA',
  GET_QUESTIONNAIRE_DATA = 'GET_QUESTIONNAIRE_DATA',
  GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE',
  DISPLAY_TOAST = 'DISPLAY_TOAST',
  GET_USER = 'GET_USER',
  GET_USER_LICENSES = 'GET_USER_LICENSES',
  GET_LICENSE = 'GET_LICENSE',
  GET_QUESTIONS_DATA = 'GET_QUESTIONS_DATA',
  GET_SEARCH_DATA = 'GET_SEARCH_DATA',
  ADD_FEATURE_FLAGS = 'ADD_FEATURE_FLAGS',
  ADD_STYLE_TEMPLATES = 'ADD_STYLE_TEMPLATES',
  ADD_SURVEY_VERSIONS = 'ADD_SURVEY_VERSIONS',
  GET_PRODUCT_DATA = 'GET_PRODUCT_DATA',
  GET_PRODUCT = 'GET_PRODUCT',
  ADD_PRODUCT = 'ADD_PRODUCT',
  GET_LINK_DATA_SET_DATA = 'GET_LINK_DATA_SET_DATA',
  GET_LINK_DATA_SET = 'GET_LINK_DATA_SET',
  ADD_LINK_DATA_SET = 'ADD_LINK_DATA_SET',
  ADD_SHARING_METHODS = 'ADD_SHARING_METHODS',
  ADD_ANALYTICS_CONFIGURATIONS = 'ADD_ANALYTICS_CONFIGURATIONS',
}
