import React from 'react';

const LicenseUsers = ({ isAdd, users = [] }: any) => {
  const getUserString = (user: {
    userId: string;
    name: string;
    email: string;
  }): string => {
    if (user.email) {
      return `${user.email || ''} (${user.name || ''})`;
    }
    return `${user.name}`;
  };

  return (
    <>
      <div className='page-header section-header'>
        <div className='page-header__title'>
          <h2>Users: </h2>
        </div>
        {isAdd && (
          <div className='page-header__actions form-header'>
            <button onClick={(event) => event.preventDefault()}>
              Manage Users
            </button>
          </div>
        )}
      </div>
      <div>
        <ul className='users-list'>
          {users.map(
            (user: { userId: string; name: string; email: string }) => (
              <li key={user.userId}>{`${getUserString(user)}`}</li>
            )
          )}
        </ul>
      </div>
    </>
  );
};

export default LicenseUsers;
