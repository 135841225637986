import { ActionType } from './actionTypes';

export const setStyleTemplatesToStore = (
  paginationData: any = {
    previous: null,
    next: null,
    hasPrevious: false,
    hasNext: false,
    total: 0,
    styleTemplates: [],
  }
) => {
  return {
    type: ActionType.ADD_STYLE_TEMPLATES,
    payload: paginationData,
  };
};
