import DataTable, { createTheme } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import { TableTheme, customTable } from '../../Constants/ThemeO2T';
import { useHistory } from 'react-router-dom';
import Loading from '../Basic/Loading';
import { formatDate } from '../../Utils/util';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';

createTheme(
  'o2t-table',
  {
    text: {
      primary: TableTheme.tTextPrimary,
    },
    background: {
      default: TableTheme.tBg,
    },
    divider: {
      default: TableTheme.tRowBorder,
    },
  },
  'light'
);

const QuestionnairesGrid = (props: any) => {
  const { data, isProductModule } = props;
  const { next, previous, paginationDetails, resetPagination } = data;
  const totalRows = data.total || null;

  const history = useHistory();

  const onPerRowsChange = (perPage: number) => {
    data.handlePageChange({
      limit: perPage,
      page: 1,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const onPageChange = (page: number) => {
    const pagination: any =
      paginationDetails.page < page ? { next } : { previous };
    data.handlePageChange({
      ...pagination,
      limit: paginationDetails.limit,
      page,
      sortBy: paginationDetails.sortBy,
      sortOrder: paginationDetails.sortOrder,
      searchTerm: paginationDetails.searchTerm,
    });
  };

  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.name,
      sortable: true,
      width: '40%',
      cell: (row: any) => (
        <div
          onClick={() => handleRowClick(row, isProductModule)}
          className='grid-col'
        >
          <div>
            <div className='grid-col__title'>
              {row.name ? row.name : 'Name'}
            </div>
            <div className='grid-col__subtitle'>
              {row.type ? row.type : 'Questionnaire'}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'Active till',
      width: '20%',
      center: true,
      selector: (row: any) =>
        row.activeTill ? formatDate(new Date(Number(row.activeTill))) : '',
      sortable: true,
    },
    {
      name: '# of questions',
      width: '20%',
      center: true,
      selector: (row: any) => (row.questionCount ? row.questionCount : '0'),
    },
    {
      name: 'Status',
      width: '20%',
      sortable: true,
      center: true,
      selector: (row: any) => row.status,
      cell: (row: any) => (
        <div
          onClick={() => handleRowClick(row, isProductModule)}
          className='grid-col'
        >
          <span className='grid-col__badge'>
            {row.status ? row.status : 'Concept'}
            {row.status === 'PUBLISHED' && (
              <FontAwesomeIcon className='ml-1' icon={faCheckCircle} />
            )}
          </span>
        </div>
      ),
    },
  ];

  const handleRowClick = (row: any, isProductModule: boolean) =>
    history.push(
      `${isProductModule ? '/products' : ''}/questionnaires/${
        row._id
      }/questions`
    );

  return (
    <>
      <AccessControl
        allowedPermissions={['list:survey']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view questionnaires'
            }
          />
        )}
      >
        <DataTable
          columns={columns}
          data={data.questionnaires || []}
          theme='o2t-table'
          customStyles={customTable}
          progressPending={data.loading}
          progressComponent={<Loading />}
          pagination={true}
          paginationServer={true}
          onSort={data.handleSortByColumn}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={onPerRowsChange}
          onChangePage={onPageChange}
          paginationDefaultPage={1}
          paginationResetDefaultPage={resetPagination}
          onRowClicked={(row: any) => handleRowClick(row, isProductModule)}
          conditionalRowStyles={[
            {
              when: (row) => row,
              style: {
                cursor: 'pointer',
              },
            },
          ]}
        />
      </AccessControl>
    </>
  );
};

export default QuestionnairesGrid;
