import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { IPage } from '../Interfaces';
import Loading from '../Components/Basic/Loading';
import QuestionnairesGrid from '../Components/QuestionnaireModule/QuestionnairesGrid';
import TopSearch from '../Components/QuestionnaireModule/TopSearch';
import AddEditQuestionnaire from '../Components/QuestionnaireModule/AddEditQuestionnaire';
import {
  ADD_QUESTIONNAIRE,
  EDIT_QUESTIONNAIRE,
  GET_PAGINATED_QUESTIONNAIRES,
} from '../Query/questionnaires.query';
import { setQuestionnaireDataToStore } from '../States/Actions/questionnaireAction';
import { QuestionnaireStatus, ToastTypes } from '../Utils/types';
import { setQuestionDataToStore } from '../States/Actions/questionAction';
import AccessControl from '../Components/AccessControl';
import Alert from '../Components/Basic/AlertComponent';
import { useRouteMatch } from 'react-router-dom';
import { GET_PAGINATED_PRODUCTS } from 'src/Query/products.query';
import { setProduct } from 'src/States/Actions/productAction';
import { useToast } from 'src/Components/Basic/Toastify';

const Questionnaires: React.FunctionComponent<IPage> = (props: any) => {
  const { path } = useRouteMatch();
  const { showToast } = useToast();

  const licenseId = props.license?._id;
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [paginationDetails, setPaginationDetails] = useState<any>({
    limit: 10,
    page: 1,
    searchTerm: props.searchData.searchTerm,
  });
  const [isProductModule, setIsProductModule] = useState(false);
  const [editQuestionnaireData, setEditQuestionnaireData] = useState<any>(null);
  const [resetPagination, setResetPagination] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [questionnaireStatus, setQuestionnaireStatus] = useState<any>(
    QuestionnaireStatus.CONCEPT
  );
  const [skipFetchQuestionnaires, setSkipFetchQuestionnaires] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<string | null>(null);
  const [productList, setProductList] = useState<any[]>([]);
  const [itemsList, setItemsList] = useState<any[]>([]);
  const [productPagination, setProductPagination] = useState<any>({
    limit: 10,
    page: 1,
  });

  function toggleCreateModal() {
    setOpenAddModal(!openAddModal);
  }

  const handleEditQuestionnaireSelect = (questionnaire: any) => {
    setEditQuestionnaireData(questionnaire);
    toggleEditModal();
  };

  const toggleEditModal = () => {
    setOpenEditModal(!openEditModal);
  };

  useEffect(() => {
    setIsProductModule(path.includes('/products/questionnaires'));
  }, [path]);

  const [fetchProducts] = useLazyQuery(GET_PAGINATED_PRODUCTS, {
    errorPolicy: 'all',
    onCompleted: (completedData) => {
      if (completedData) {
        setProductList(completedData.products?.results);
        setItemsList(
          completedData.products?.results.map((product: any) => {
            return {
              value: product._id,
              label: product.name,
            };
          })
        );
      }
    },
  });

  useEffect(() => {
    if (isProductModule) {
      fetchProducts({
        variables: { ...productPagination },
      });
    }
  }, [fetchProducts, isProductModule, productPagination]);

  useEffect(() => {
    if (licenseId || isProductModule) {
      setSkipFetchQuestionnaires(false);
    }
  }, [licenseId, selectedProduct, isProductModule]);

  const getParametersForQuestionnaires = (
    params: any,
    licenseId: string | null,
    productId: string | null,
    isProductModule: boolean,
    licenseOptions?: any
  ) => {
    const obj = isProductModule
      ? { ...params, productId, isProductItem: isProductModule }
      : {
          ...params,
          licenseId,
          ...licenseOptions,
          isProductItem: isProductModule,
        };
    return obj;
  };

  useEffect(() => {
    if (
      (paginationDetails.searchTerm && !props.searchData.searchTerm) ||
      (!paginationDetails.searchTerm && props.searchData.searchTerm)
    ) {
      setPaginationDetails((prevPaginationDetails: any) => {
        return {
          ...prevPaginationDetails,
          page: 1,
          next: null,
          previous: null,
          searchTerm: props.searchData.searchTerm,
        };
      });
      setResetPagination(true);
    } else {
      setPaginationDetails((prevPaginationDetails: any) => {
        return {
          ...prevPaginationDetails,
          searchTerm: props.searchData.searchTerm,
        };
      });
      setResetPagination(false);
    }
  }, [props, paginationDetails.searchTerm, selectedProduct]);

  const [
    addQuestionnaire,
    { error: addQuestionnaireError, loading: addQuestionnaireLoading },
  ] = useMutation(ADD_QUESTIONNAIRE, {
    refetchQueries: [
      {
        query: GET_PAGINATED_QUESTIONNAIRES,
        variables: getParametersForQuestionnaires(
          {
            page: 1,
            limit: 10,
            licenseId,
            searchTerm: '',
          },
          licenseId,
          selectedProduct,
          isProductModule,
          {
            isArchived: false,
            status: QuestionnaireStatus.CONCEPT.toUpperCase(),
          }
        ),
      },
    ],
    errorPolicy: 'none',
    onCompleted: () => {
      toggleCreateModal();
      showToast('Questionnaire added successfully');
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const [
    editSelectedQuestionnaire,
    { error: editQuestionnaireError, loading: editQuestionnaireLoading },
  ] = useMutation(EDIT_QUESTIONNAIRE, {
    refetchQueries: [
      {
        query: GET_PAGINATED_QUESTIONNAIRES,
        variables: getParametersForQuestionnaires(
          {
            ...paginationDetails,
            limit: paginationDetails.limit,
            searchTerm: props.searchData.searchTerm,
          },
          licenseId,
          selectedProduct,
          isProductModule,
          {
            isArchived: false,
            status: questionnaireStatus.toUpperCase(),
          }
        ),
      },
    ],
    errorPolicy: 'none',
    onCompleted: () => {
      toggleEditModal();
      showToast('Questionnaire updated successfully');
    },
    onError: (error: any) => {
      showToast(error.message, ToastTypes.ERROR);
      console.error(error);
    },
  });

  const handleSortByColumn = (sortBy: { name: string }, sortOrder: string) => {
    setPaginationDetails({
      limit: paginationDetails.limit,
      page: 1,
      sortBy: sortBy.name.toUpperCase().replaceAll(' ', '_'),
      sortOrder: sortOrder.toUpperCase(),
      searchTerm: props.searchData.searchTerm,
    });
  };

  const handlePageChange = (paginationDetails: any) => {
    setPaginationDetails({
      ...paginationDetails,
      next: paginationDetails.page === 1 ? null : paginationDetails.next,
      previous:
        paginationDetails.page === 1 ? null : paginationDetails.previous,
      searchTerm: props.searchData.searchTerm,
    });
  };

  const { loading, error } = useQuery(GET_PAGINATED_QUESTIONNAIRES, {
    variables: getParametersForQuestionnaires(
      {
        ...paginationDetails,
        searchTerm: props.searchData.searchTerm,
      },
      licenseId,
      selectedProduct,
      isProductModule,
      {
        isArchived: showArchived,
        status: questionnaireStatus.toUpperCase(),
      }
    ),
    errorPolicy: 'all',
    skip: skipFetchQuestionnaires,
    onCompleted: (completedData: any) => {
      if (completedData) {
        props.setQuestionnaireDataToStore(completedData.questionnaires);
        props.setQuestionDataToStore();
      }
    },
  });

  const handleScroll = (event: any) => {
    setProductPagination({
      ...productPagination,
      limit: productPagination.limit + 10,
    });
  };

  return (
    <div className='questionnaires-screen'>
      {!isProductModule ? (
        <AccessControl allowedPermissions={['view:surveys_page']}>
          <TopSearch
            showArchived={showArchived}
            setShowArchived={setShowArchived}
            value={questionnaireStatus}
            onChange={setQuestionnaireStatus}
            itemList={[
              {
                value: QuestionnaireStatus.CONCEPT,
                label: QuestionnaireStatus.CONCEPT,
              },
              {
                value: QuestionnaireStatus.PUBLISHED,
                label: QuestionnaireStatus.PUBLISHED,
              },
            ]}
            isProductModule={isProductModule}
          />
        </AccessControl>
      ) : (
        <TopSearch
          value={selectedProduct}
          onChange={(selectedProduct: any) => {
            setSelectedProduct(selectedProduct);
            props.setProduct(
              productList.find(
                (product: any) => product._id === selectedProduct
              )
            );
          }}
          itemList={itemsList}
          isProductModule={isProductModule}
          onScroll={handleScroll}
        />
      )}
      <AccessControl
        allowedPermissions={['view:surveys_page']}
        renderNoAccess={() => (
          <Alert
            message={
              'Unauthorized. You do not have permission to view questionnaires page'
            }
          />
        )}
      >
        <div className='page-header'>
          <div className='page-header__title'>
            <h2>{`${isProductModule ? 'Product ' : ''}Questionnaires`}</h2>
          </div>
          <div className='page-header__actions'>
            {licenseId && (
              <AccessControl allowedPermissions={['create:survey']}>
                <button onClick={toggleCreateModal}>
                  {`Add ${isProductModule ? 'Product ' : ''}Questionnaire`}
                  <span>
                    <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
                  </span>
                </button>
              </AccessControl>
            )}
          </div>
        </div>
        <div className='grid-wrapper'>
          <QuestionnairesGrid
            data={{
              questionnaires: props.questionnaires,
              total: props.total,
              loading,
              error,
              paginationDetails,
              next: props.next,
              previous: props.previous,
              resetPagination,
              handlePageChange,
              handleSortByColumn,
              handleEditQuestionnaireSelect,
            }}
            isProductModule={isProductModule}
          />
        </div>
        {openAddModal && (
          <AddEditQuestionnaire
            modalIsOpen={openAddModal}
            toggleModal={toggleCreateModal}
            modalTitle={'Add Questionnaire'}
            error={addQuestionnaireError}
            onSubmit={addQuestionnaire}
            isLoading={addQuestionnaireLoading}
            isProductModule={isProductModule}
            productsList={itemsList}
          />
        )}
        {openEditModal && (
          <AddEditQuestionnaire
            modalIsOpen={openEditModal}
            toggleModal={toggleEditModal}
            modalTitle={'Edit Questionnaire'}
            modalData={editQuestionnaireData}
            error={editQuestionnaireError}
            onSubmit={editSelectedQuestionnaire}
            isLoading={editQuestionnaireLoading}
          />
        )}
      </AccessControl>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    license: state.license,
    questionnaires: state.questionnaires.results,
    hasNext: state.questionnaires.hasNext,
    next: state.questionnaires.next,
    hasPrevious: state.questionnaires.hasPrevious,
    previous: state.questionnaires.previous,
    total: state.questionnaires.total,
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, {
  setQuestionnaireDataToStore,
  setQuestionDataToStore,
  setProduct,
})(
  withAuthenticationRequired(Questionnaires, {
    onRedirecting: () => <Loading />,
  })
);
