import { QuestionTypes } from 'src/Utils/types';

const getConditions = (formData: any, questionsList: any[]) => {
  if (!formData?.condition) {
    return;
  }
  return formData.condition.map(
    ({
      condition,
      answers,
      display,
      connector,
      matrixQuestion,
      question: selectedParentQuestion,
    }: any) => {
      const parentQuestion = questionsList?.find(
        (question: any) => question?._id === selectedParentQuestion?.value
      );
      return {
        answers,
        condition: { ...condition, is: condition?.value === 'is' },
        display: { ...display, show: display?.value === 'show' },
        parentQuestion,
        connector,
        matrixQuestion,
      };
    }
  );
};

export const formatStartingPageData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      conditions: conditions,
      attributes: {
        languageToggle: formData.languageToggle,
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};

export const formatOpenQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: (formData.desc || '') + '|||' + formData.placeholder,
      conditions: conditions,
      attributes: {
        inputKeyboard: formData.keyboard,
        maxInput: formData.maxCharacters,
        showSkip: formData.optional || false,
        sentimentAnalysis: formData.sentimentAnalysis,
        hideBackButton: formData.hideBackButton,
        regexMask: formData.regex,
        minNumber: formData.minNumber,
        maxNumber: formData.maxNumber,
      },
    },
  };
};

export const formatRatingQuestionData = (
  formData: any,
  data: any,
  choices: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      ratings: choices,
      conditions: conditions,
      attributes: {
        optional: formData.optional || false,
        isNPS: formData.isNPS,
        showAsNumbers: !formData.customAnswerText,
        numberOfChoices: formData.isNPS ? 11 : formData.numberOfChoices,
      },
    },
  };
};

export const formatChoicesQuestionData = (
  formData: any,
  data: any,
  choices: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);

  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      choices: choices,
      conditions: conditions,
      attributes: {
        optional: formData.optional || false,
        randomize: formData.randomize,
        multipleSelection: data.singleChoice
          ? !data.singleChoice
          : formData.multipleSelection,
        numberOfChoices:
          type === QuestionTypes.SINGLE_CHOICE ? 1 : formData.numberOfChoices,
        lowestNumberOfChoices:
          type === QuestionTypes.SINGLE_CHOICE
            ? 1
            : formData.lowestNumberOfChoices,
      },
    },
  };
};

export const formatInfoQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      conditions: conditions,
      attributes: {
        hideBackButton: formData.hideBackButton,
        showAnswers: formData.showAnswers,
      },
    },
  };
};

export const formatFinalQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      conditions: conditions,
      attributes: {
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};

export const formatMatrixChoiceQuestionData = (
  formData: any,
  data: any,
  choices: any,
  answers: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);

  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      choices: choices,
      answers: answers,
      conditions: conditions,
      attributes: {
        optional: formData.optional || false,
        randomize: formData.randomize,
        multipleSelection: data.singleChoice
          ? !data.singleChoice
          : formData.multipleSelection,
      },
    },
  };
};

export const formatMatrixRatingQuestionData = (
  formData: any,
  data: any,
  choices: any,
  answers: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);

  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      choices: choices,
      answers: answers,
      conditions: conditions,
      attributes: {
        optional: formData.optional || false,
        showAsNumbers: !formData.customAnswerText,
        numberOfChoices: formData.numberOfChoices,
      },
    },
  };
};

export const formatCustomHTMLPageQuestionData = (
  formData: any,
  data: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);
  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      desc: formData.desc,
      conditions: conditions,
      attributes: {
        languageToggle: formData.languageToggle,
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};

export const formatRankingQuestionData = (
  formData: any,
  data: any,
  choices: any,
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);

  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      choices: choices,
      conditions: conditions,
      attributes: {
        optional: formData.optional || false,
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};

export const formatFormQuestionData = (
  formData: any,
  data: any,
  selectedQuestionsList: any[],
  questionsList: any[],
  type?: string
) => {
  const conditions = getConditions(formData, questionsList);

  return {
    variables: {
      questionId: data.question._id,
      label: formData.label,
      class: data.question.class,
      type: type || data.question.type,
      title: formData.title,
      desc: formData.desc,
      choices: selectedQuestionsList.map((question: any) => ({
        _id: question.value,
      })),
      conditions: conditions,
      attributes: {
        hideBackButton: formData.hideBackButton,
      },
    },
  };
};
