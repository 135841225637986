import { ActionType } from './actionTypes';

export const setQuestionDataToStore = (
  paginationData: any = {
    previous: null,
    next: null,
    hasPrevious: false,
    hasNext: false,
    total: 0,
    results: [],
  }
) => {
  return {
    type: ActionType.GET_QUESTIONS_DATA,
    payload: {
      ...paginationData,
      results: [...(paginationData?.results || [])].sort((a: any, b: any) => {
        return a.order - b.order;
      }),
    },
  };
};
