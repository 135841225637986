import React, { Fragment, useEffect, useRef, useState } from 'react';
import o2tLogo from '../../assets/logo.svg';
import { useAuth0 } from '@auth0/auth0-react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBell,
  faChevronDown,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { useLocation } from 'react-router-dom';
import LicenseListPanel from '../Basic/LicenseListPanel';
import { connect } from 'react-redux';
import { setSearchData } from '../../States/Actions/searchAction';
import UserQuickAccessPanel from '../Basic/UserQuickAccessPanel';

function HeaderTitle() {
  const location = useLocation();
  const [title, setTitle] = useState('');

  useEffect(() => {
    switch (location.pathname) {
      case '/':
        setTitle('Dashboard');
        break;
      case '/questionnaires':
        setTitle('Questionnaires');
        break;
      case '/users':
        setTitle('Users');
        break;
      case '/licenses':
        setTitle('Licenses');
        break;
      case '/licenses/add':
        setTitle('Licenses');
        break;
      case '/profile':
        setTitle('Profile');
        break;
      case '/templates':
        setTitle('Templates');
        break;
      case '/products':
        setTitle('Products');
        break;
      case '/link':
        setTitle('Link device');
        break;
    }
  }, [location]);

  return <>{title}</>;
}

const Header = (props: any) => {
  const { user, isAuthenticated } = useAuth0();

  const [searchTerm, setSearchTerm] = useState(props.searchData.searchTerm);
  const [openUserPanel, setOpenUserPanel] = useState(false);

  const [hideSearchBar, setHideSearchBar] = useState(false);
  const [hideLicenseList, setHideLicenseList] = useState(false);
  /**
   * TODO: to be used when notification feature is enabled.
   * const [disableNotifications, setDisableNotifications] = useState(true);
   */
  const disableNotifications = true;

  const location = window.location.pathname;

  useEffect(() => {
    const questionnaireQuestionsRegEx: any =
      /questionnaires\/[A-Za-z0-9]*\/questions/g;
    if (location === '/' || questionnaireQuestionsRegEx.test(location)) {
      setHideSearchBar(true);
      setHideLicenseList(true);
    } else {
      setHideSearchBar(false);
      setHideLicenseList(false);
    }
  }, [location]);

  const handleSearchBarClassChange = () => {
    props.setSearchData({
      isOpen: !props.searchData.isOpen,
      searchTerm,
    });
    setSearchTerm(searchTerm);
  };

  const handleKeypress = (event: any) => {
    if (event.code === 'Enter') {
      props.setSearchData({
        isOpen: true,
        searchTerm,
      });
    }
  };

  const handleInputChange = (event: any) => {
    if (event.target.value === '') {
      props.setSearchData({
        isOpen: true,
        searchTerm: '',
      });
    }
    setSearchTerm(event.target.value || '');
  };

  useEffect(() => {
    setSearchTerm(props.searchData.searchTerm);
  }, [props]);

  const ref = useRef<any>(null);
  useEffect(() => {
    if (ref.current && props.searchData.isOpen) {
      ref.current.focus();
    }
  }, [props]);

  useEffect(() => {
    window.onclick = (event: any) => {
      if (
        event.type === 'click' &&
        !event?.composedPath()?.find((path: any) => path.id === 'user-panel')
      ) {
        setOpenUserPanel(false);
      } else {
        setOpenUserPanel(!openUserPanel);
      }
    };
  });

  return (
    <>
      <div className={`o2t-header ${isAuthenticated ? 'logged-in' : ''}`}>
        <div className='header-wrapper'>
          <div className='app-header'>
            <div className='app-header__logo'>
              <img src={o2tLogo} alt='Brand Logo' />
            </div>
            <h1 className='app-header__title'>
              {isAuthenticated ? (
                <HeaderTitle />
              ) : (
                'END-TO-END EXPERIENCE MANAGEMENT PLATFORM'
              )}
            </h1>
          </div>

          {isAuthenticated && (
            <div className='notify-area'>
              {!hideSearchBar && (
                <Fragment>
                  <div className='notify-area__search-bar '>
                    <input
                      ref={ref}
                      type='search'
                      className={props.searchData.isOpen ? 'show' : ''}
                      value={searchTerm}
                      onChange={(event) => handleInputChange(event)}
                      onKeyPress={handleKeypress}
                    />
                  </div>
                  <div className='notify-area__search'>
                    <div onClick={() => handleSearchBarClassChange()}>
                      <FontAwesomeIcon icon={faSearch} />
                    </div>
                  </div>
                </Fragment>
              )}
              <div
                className={`notify-area__bell${
                  disableNotifications ? '--disable' : ''
                }`}
              >
                <FontAwesomeIcon icon={faBell} />
              </div>
              {!hideLicenseList && (
                <div className='notify-area__bell'>
                  <LicenseListPanel />
                </div>
              )}
              <div
                id='user-panel'
                className='notify-area__user'
                onClick={() => setOpenUserPanel(!openUserPanel)}
              >
                <div className='notify-area__user--picture'>
                  <img src={user?.picture} alt='Profile' width='30' />
                </div>
                <div className='notify-area__user--name'>{user?.name}</div>
                <UserQuickAccessPanel openPanel={openUserPanel} user={user} />
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    searchData: state.searchData,
  };
};

export default connect(mapStateToProps, {
  setSearchData,
})(Header);
