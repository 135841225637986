import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { customModal } from '../../Constants/ThemeO2T';

const AccountLinkConfirmationModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalTitle,
  selectedAccount,
  onAccountLinkClick,
  isLoading,
}) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customModal}
        contentLabel='Link account confirmation modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <p>
              Are you sure you want to link the selected account with your
              account?
            </p>
          </div>
          <div className='o2t-modal__footer'>
            <button
              onClick={() => toggleModal()}
              disabled={isLoading}
              className='link-button mr-2'
            >
              Cancel
            </button>
            <button
              onClick={() => onAccountLinkClick(selectedAccount)}
              disabled={isLoading}
            >
              Link Account
              <span>
                <FontAwesomeIcon className='ml-2' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AccountLinkConfirmationModal;
