import { gql } from '@apollo/client';

export const GET_PAGINATED_STYLE_TEMPLATES = gql`
  query StyleTemplates(
    $searchTerm: String
    $styleTemplateType: String
    $licenseId: ID
    $productId: ID
    $sortBy: StyleTemplateSortingOption
    $sortOrder: SortOrder
    $limit: Int!
    $next: String
    $previous: String
    $isProductItem: Boolean
  ) {
    styleTemplates(
      searchTerm: $searchTerm
      styleTemplateType: $styleTemplateType
      licenseId: $licenseId
      productId: $productId
      sortBy: $sortBy
      sortOrder: $sortOrder
      limit: $limit
      next: $next
      previous: $previous
      isProductItem: $isProductItem
    ) {
      styleTemplates {
        _id
        createdBy
        updatedBy
        createdAt
        updatedAt
        styleTemplateType
        name
        backgroundColor
        titleColor
        subTextColor
        buttonColor
        buttonArrowColor
        buttonBorderColor
        logo
        backgroundImage
        customCSS
        licenseId
        productId
        isProductItem
      }
      previous
      hasPrevious
      next
      hasNext
      total
    }
  }
`;

export const DELETE_STYLE_TEMPLATE = gql`
  mutation DeleteStyleTemplate($styleTemplateId: ID!) {
    deleteStyleTemplate(_id: $styleTemplateId)
  }
`;

export const ADD_STYLE_TEMPLATE = gql`
  mutation AddStyleTemplate(
    $name: String!
    $styleTemplateType: String!
    $backgroundColor: String!
    $titleColor: String!
    $subTextColor: String!
    $buttonColor: String!
    $buttonArrowColor: String!
    $buttonBorderColor: String!
    $logo: String
    $backgroundImage: String
    $customCSS: String
    $licenseId: ID
    $productId: ID
    $isProductItem: Boolean
  ) {
    addStyleTemplate(
      name: $name
      styleTemplateType: $styleTemplateType
      backgroundColor: $backgroundColor
      titleColor: $titleColor
      subTextColor: $subTextColor
      buttonColor: $buttonColor
      buttonArrowColor: $buttonArrowColor
      buttonBorderColor: $buttonBorderColor
      logo: $logo
      backgroundImage: $backgroundImage
      customCSS: $customCSS
      licenseId: $licenseId
      productId: $productId
      isProductItem: $isProductItem
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      styleTemplateType
      name
      backgroundColor
      titleColor
      subTextColor
      buttonColor
      buttonArrowColor
      buttonBorderColor
      logo
      backgroundImage
      customCSS
      licenseId
      productId
      isProductItem
    }
  }
`;

export const EDIT_STYLE_TEMPLATE = gql`
  mutation EditStyleTemplate(
    $styleTemplateId: ID!
    $name: String!
    $styleTemplateType: String!
    $backgroundColor: String!
    $titleColor: String!
    $subTextColor: String!
    $buttonColor: String!
    $buttonArrowColor: String!
    $buttonBorderColor: String!
    $logo: String
    $backgroundImage: String
    $customCSS: String
    $licenseId: ID
  ) {
    updateStyleTemplate(
      _id: $styleTemplateId
      name: $name
      styleTemplateType: $styleTemplateType
      backgroundColor: $backgroundColor
      titleColor: $titleColor
      subTextColor: $subTextColor
      buttonColor: $buttonColor
      buttonArrowColor: $buttonArrowColor
      buttonBorderColor: $buttonBorderColor
      logo: $logo
      backgroundImage: $backgroundImage
      customCSS: $customCSS
      licenseId: $licenseId
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      styleTemplateType
      name
      backgroundColor
      titleColor
      subTextColor
      buttonColor
      buttonArrowColor
      buttonBorderColor
      logo
      backgroundImage
      customCSS
      licenseId
    }
  }
`;
