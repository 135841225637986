import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

import { customModal } from '../../Constants/ThemeO2T';

export const DeleteProductModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalTitle,
  error,
  modalData,
  onDelete,
  isLoading,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleDelete = () => {
    onDelete({ variables: { productId: modalData._id } });
  };

  useEffect(() => {
    setIsOpen(modalIsOpen);
  }, [modalIsOpen]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        style={customModal}
        contentLabel='Delete Products Modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <p>
              You are about to delete
              <strong> {modalData.name} </strong>
              from the One2Ten platform
            </p>

            <p>Are you certain that you want to delete this product?</p>
          </div>
          <div className='alert-wrapper'>{error && <p>{error.message}</p>}</div>
          <div className='o2t-modal__footer'>
            <button
              onClick={toggleModal}
              className='link-button mr-2'
              disabled={isLoading}
            >
              Cancel
            </button>
            <button onClick={handleDelete} disabled={isLoading}>
              Delete
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};
