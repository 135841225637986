import { ActionType } from './actionTypes';

export const setLicenseDataToStore = (
  paginationData: any = {
    previous: null,
    next: null,
    hasPrevious: false,
    hasNext: false,
    total: 0,
    results: [],
  }
) => {
  return {
    type: ActionType.GET_LICENSE_DATA,
    payload: paginationData,
  };
};

export const displayToastMessage = (toast: any = null) => {
  return {
    type: ActionType.DISPLAY_TOAST,
    payload: toast,
  };
};

export const setLicense = (license: any = null) => {
  return {
    type: ActionType.GET_LICENSE,
    payload: license,
  };
};
