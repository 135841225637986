export const QuestionsData = [
  {
    id: 1,
    title: 'Rating question',
    type: 'RATING',
    label: 'Rating question',
  },
  {
    id: 2,
    title: 'Choice question',
    type: 'CHOICE',
    label: 'Choice question',
  },
  {
    id: 3,
    title: 'Matrix question',
    type: 'MATRIX',
    label: 'Matrix question',
  },
  {
    id: 4,
    title: 'Open question',
    type: 'OPEN',
    label: 'Open question',
  },
  {
    id: 5,
    title: 'Type of Pages',
    type: 'PAGE',
    label: 'Type of Pages',
  },
  {
    id: 6,
    title: 'Conditional',
    type: 'CONDITIONAL',
    label: 'Conditional',
  },
  {
    id: 7,
    title: 'Ranking question',
    type: 'RANKING',
    label: 'Ranking question',
  },
  {
    id: 8,
    title: 'Form',
    type: 'FORM',
    label: 'Form',
  },
];

export const QuestionTypeList = [
  {
    id: 1,
    title: 'Rating question',
    type: 'RATING',
    label: 'Rating question',
  },
  {
    id: 2,
    title: 'Single choice',
    type: 'SINGLE_CHOICE',
    label: 'Single choice',
  },
  {
    id: 3,
    title: 'Multiple choice',
    type: 'MULTIPLE_CHOICE',
    label: 'Multiple choice',
  },
  {
    id: 4,
    title: 'Rating page',
    type: 'RATING_PAGE',
    label: 'Matrix - Rating page',
  },
  {
    id: 5,
    title: 'Choices page',
    type: 'CHOICES_PAGE',
    label: 'Matrix - Choices page',
  },
  {
    id: 6,
    title: 'Open question',
    type: 'OPEN',
    label: 'Open question',
  },
  {
    id: 7,
    title: 'Question',
    type: 'LANDING',
    label: 'Start',
  },
  {
    id: 8,
    title: 'Question',
    type: 'INFO',
    label: 'Informational',
  },
  {
    id: 9,
    title: 'Question',
    type: 'FINAL',
    label: 'Final',
  },
  {
    id: 10,
    title: 'Conditional',
    type: 'CONDITIONAL',
    label: 'Conditional',
  },
  {
    id: 11,
    title: 'Ranking question',
    type: 'RANKING',
    label: 'Ranking question',
  },
  {
    id: 11,
    title: 'Form',
    type: 'FORM',
    label: 'Form',
  },
];

export const QuestionSetData: any = {
  RATING: [
    {
      id: 1,
      title: 'Rating 1-10',
      type: '1-10',
      label: 'Rating 1-10',
      description:
        'With a rating question, resondents can rate a question using a 1-10 scale. The 1-10 rating is the most common scale used for surveys. With all rating questions it is advised to define the  ratings in words in the subtext.',
    },
    {
      id: 2,
      title: 'Rating 1-5',
      type: '1-5',
      label: 'Rating 1-5',
      description:
        'With this question type respondents can rate subjects using a common scale. A 1-5 rating gives respondents the option to be neutralwith statements or opinions. With all rating questions it is advised to define the  ratings in words in the subtext.',
    },
    {
      id: 3,
      title: 'CSAT',
      type: 'CSAT',
      label: 'CSAT',
      description:
        'A CSAT (customer satisfaction score) is a key performance indicator and tracks how satisfied customers are withing the products and/or services.',
    },
    {
      id: 4,
      title: 'CES',
      type: 'CES',
      label: 'CES',
      description:
        'A CES (customer effort score) measures how much effort a customer has to exert to receive service or to purchase a product.',
    },
    {
      id: 5,
      title: 'NPS',
      type: 'NPS',
      label: 'NPS',
      description:
        'De Net Promotor Score (NPS) is an important key performance indicator for customer loyalty and satisfaction. NPS questions will have a rating from 0-10. 0-6 are detractors: these are unhappy customers who are unlikely to stay with the company. 7-8 are passives: they are happy but are receptive to your competitors or peers. 9-10 are promoters: they are loyal and enthusiastic customers. The NPS score will give a score between -100 to +100 and is calculated by % promotors - % detractors. A high positive score is desirable.',
    },
    {
      id: 6,
      title: 'TNPS',
      type: 'TNPS',
      label: 'TNPS',
      description:
        'The TNPS (Transactional Net Promotor Score) is used after a transaction to address friction points in the customer journey. It is used to identify and optimize transactions like onboarding, calls, interactions or post-purchase.',
    },
  ],
  CHOICE: [
    {
      id: 1,
      title: 'Single choice',
      type: 'SINGLE_CHOICE',
      label: 'Single choice',
      description:
        'With this question type respondents can only choose one option out of the different options given for a specific question.',
    },
    {
      id: 2,
      title: 'Multiple choice',
      type: 'MULTIPLE_CHOICE',
      label: 'Multiple choice',
      description:
        'With this question type respondents can choose multiple answer options that are given for a specific question',
    },
  ],
  MATRIX: [
    {
      id: 1,
      title: 'Rating',
      type: 'RATING_PAGE',
      label: 'Rating',
      description:
        'A matrix question gives you the opportunity to capture different questions and answer options in one single table. The rows represent the questions and the columns a set of predefined answer options that apply to each question in the row. In this type of matrix question, the answers are given on a rating scale.',
    },
    {
      id: 2,
      title: 'Choices',
      type: 'CHOICES_PAGE',
      label: 'Choices',
      description:
        'A matrix question gives you the opportunity to capture different questions and answer options in one single table. The rows represent the questions and the columns a set of predefined answer options that apply to each question in the row. In this type of matrix question, the answers are given in words.',
    },
  ],
  OPEN: [
    {
      id: 1,
      title: 'Open question',
      type: 'NUMBER',
      label: 'Number',
      description:
        'With this question type the respondent can fill in free text that is limited to the format of numbers (1, 2,3 ..)',
    },
    {
      id: 2,
      title: 'Open question',
      type: 'EMAIL',
      label: 'Email',
      description:
        'With this question type the respondent can fill in free text that is limited to email formats (name@test.com)',
    },
    {
      id: 3,
      title: 'Open question',
      type: 'TEXT',
      label: 'Text',
      description:
        'With this question type the respondent can fill in free text in each format they choose. ',
    },
    {
      id: 4,
      title: 'Open question',
      type: 'DATE',
      label: 'Date',
      description:
        'With this question type the respondent can fill in a date. ',
    },
  ],
  PAGE: [
    {
      id: 1,
      title: 'Start',
      type: 'LANDING',
      label: 'Start',
      description:
        'A start page is the first page the respondent sees when starting the survey. This page is informative and is generally used to welcome the respondent to the survey and to indicate how much time the survey will take. The respondent can move away from this page by clicking on the page.',
    },
    {
      id: 2,
      title: 'Info',
      type: 'INFO',
      label: 'Info',
      description:
        'An Info page gives you the option to give additional information to the respondent, for example on the context of a survey or its goal. The respondent can move away from this page by clicking on the page.',
    },
    {
      id: 3,
      title: 'Final',
      type: 'FINAL',
      label: 'Final',
      description:
        'The Final page is the last page the respondent sees when finishing the survey. This page is generally used to thank the respondent for their time and effort. After clicking this page the survey will be closed.',
    },
    {
      id: 4,
      title: 'Custom Start',
      type: 'CUSTOM_LANDING',
      label: 'Custom Start',
      description:
        'A start page is the first page the respondent sees when starting the survey. This page is informative and is generally used to welcome the respondent to the survey and to indicate how much time the survey will take. The respondent can move away from this page by clicking on the page.',
    },
    {
      id: 5,
      title: 'Custom Final',
      type: 'CUSTOM_FINAL',
      label: 'Custom Final',
      description:
        'The Final page is the last page the respondent sees when finishing the survey. This page is generally used to thank the respondent for their time and effort. After clicking this page the survey will be closed.',
    },
  ],
  CONDITIONAL: [
    {
      id: 1,
      title: 'Adaptive',
      type: 'CONDITIONAL',
      label: 'Adaptive',
      description:
        'Conditional questions are not automatically asked to a respondent. This type of questions is dependent on the previous answers of the respondent. For example, if a respondents gives a bad score to a service, an additional question will pop up: what are the reasons for your bad score.',
    },
  ],
  RANKING: [
    {
      id: 1,
      title: 'Ranking',
      type: 'RANKING',
      label: 'Ranking',
      description:
        'With this question type respondents can drag and rank multiple answer options that are given for a specific question',
    },
  ],
  FORM: [
    {
      id: 1,
      title: 'Form',
      type: 'FORM',
      label: 'Form',
      description:
        'With this question type a set of questions can be displayed to a single page',
    },
  ],
};
