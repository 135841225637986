import { combineReducers } from 'redux';
import {
  licensePaginationDataReducer,
  licenseReducer,
  licenseToastReducer,
} from './licenseReducers';
import { loggedInUserReducer, userPaginationDataReducer } from './userReducers';
import {
  questionnairePaginationDataReducer,
  questionnaireReducer,
} from './questionnaireReducers';

import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { questionPaginationDataReducer } from './questionReducer';
import { searchDataReducer } from './searchReducer';
import { featureFlagReducer } from './featureFlagReducer';
import { styleTemplatePaginationDataReducer } from './styleTemplateReducer';
import { surveyVersionPaginationDataReducer } from './surveyVersionReducer';
import {
  productPaginationDataReducer,
  setProductReducer,
} from './productReducer';
import {
  linkDataSetPaginationDataReducer,
  setLinkDataSetReducer,
} from './linkDataSetReducers';
import { sharingMethodPaginationDataReducer } from './sharingMethodReducer';
import { analyticsConfigurationsPaginationDataReducer } from './analyticsConfigurationReducer';

const persistConfig = {
  key: 'root',
  storage,
};

const reducers = combineReducers({
  users: userPaginationDataReducer,
  licenses: licensePaginationDataReducer,
  questionnaires: questionnairePaginationDataReducer,
  licenseToast: licenseToastReducer,
  user: loggedInUserReducer,
  license: licenseReducer,
  questionnaire: questionnaireReducer,
  questions: questionPaginationDataReducer,
  searchData: searchDataReducer,
  featureFlags: featureFlagReducer,
  styleTemplates: styleTemplatePaginationDataReducer,
  surveyVersions: surveyVersionPaginationDataReducer,
  products: productPaginationDataReducer,
  product: setProductReducer,
  linkDataSets: linkDataSetPaginationDataReducer,
  linkDataSet: setLinkDataSetReducer,
  sharingMethods: sharingMethodPaginationDataReducer,
  analyticsConfigurations: analyticsConfigurationsPaginationDataReducer,
});

export const persistedReducer = persistReducer(persistConfig, reducers);

export type RootState = ReturnType<typeof reducers>;
