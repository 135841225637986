import { gql } from '@apollo/client';

export const QUESTIONS = gql`
  query GetQuestions(
    $questionnaireId: ID!
    $limit: Int!
    $next: String
    $previous: String
  ) {
    questions(
      questionnaireId: $questionnaireId
      limit: $limit
      next: $next
      previous: $previous
    ) {
      previous
      hasPrevious
      next
      hasNext
      total
      results {
        _id
        createdBy
        updatedBy
        createdAt
        updatedAt
        order
        pageId
        questionnaireId
        label
        class
        type
        parentQuestion
        followupQuestion
        title
        desc
        choices
        answers
        ratings
        conditions
        body
        version
        attributes
      }
    }
  }
`;

export const ADD_QUESTION = gql`
  mutation AddQuestion(
    $questionnaireId: ID!
    $pageId: String!
    $label: String
    $class: String!
    $type: String!
    $title: String
    $desc: String
    $parentQuestion: ID
    $followupQuestion: ID
    $body: [JSONObject]
    $attributes: JSONObject
  ) {
    addQuestion(
      questionnaireId: $questionnaireId
      pageId: $pageId
      label: $label
      class: $class
      type: $type
      title: $title
      desc: $desc
      followupQuestion: $followupQuestion
      parentQuestion: $parentQuestion
      body: $body
      attributes: $attributes
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      order
      pageId
      questionnaireId
      label
      class
      type
      parentQuestion
      followupQuestion
      title
      desc
      choices
      answers
      ratings
      body
      version
      attributes
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion(
    $questionId: ID!
    $label: String!
    $class: String!
    $type: String!
    $title: String!
    $desc: String
    $ratings: [JSONObject]
    $choices: [JSONObject]
    $answers: [JSONObject]
    $conditions: [JSONObject]
    $parentQuestion: ID
    $followupQuestion: ID
    $attributes: JSONObject
  ) {
    updateQuestion(
      _id: $questionId
      label: $label
      class: $class
      type: $type
      title: $title
      desc: $desc
      ratings: $ratings
      choices: $choices
      answers: $answers
      conditions: $conditions
      followupQuestion: $followupQuestion
      parentQuestion: $parentQuestion
      attributes: $attributes
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      order
      pageId
      questionnaireId
      label
      class
      type
      parentQuestion
      followupQuestion
      title
      desc
      choices
      answers
      ratings
      conditions
      body
      version
      attributes
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($questionId: ID) {
    deleteQuestion(_id: $questionId)
  }
`;

export const REORDER_QUESTIONS = gql`
  mutation ReOrderQuestions($questions: [QuestionOrder]) {
    reOrderQuestions(questions: $questions)
  }
`;
