import { ActionType } from '../Actions/actionTypes';

export const analyticsConfigurationsPaginationDataReducer = (
  state = {},
  action: any
) => {
  if (action.type === ActionType.ADD_ANALYTICS_CONFIGURATIONS) {
    return { ...state, ...action.payload };
  }
  return state;
};
