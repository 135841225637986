import { gql } from '@apollo/client';

export const GET_PAGINATED_SURVEY_VERSIONS = gql`
  query GetSurveyVersions(
    $questionnaireId: ID!
    $sortBy: SurveyVersionSortingOption
    $sortOrder: SortOrder
    $limit: Int!
    $next: String
    $previous: String
  ) {
    surveyVersions(
      questionnaireId: $questionnaireId
      sortBy: $sortBy
      sortOrder: $sortOrder
      limit: $limit
      next: $next
      previous: $previous
    ) {
      results {
        _id
        createdAt
        updatedAt
        questionnaireId
        licenseId
        survey
      }
      previous
      hasPrevious
      next
      hasNext
      total
    }
  }
`;
