import { gql } from '@apollo/client';

export const ADD_USER = gql`
  mutation AddQmsuser(
    $email: String!
    $password: String!
    $role: String
    $user_metadata: TypelessData
  ) {
    addQmsuser(
      email: $email
      password: $password
      role: $role
      user_metadata: $user_metadata
    ) {
      user_id
      email
      name
      nickname
      picture
      app_metadata
      user_metadata
      role
      identities {
        provider
        user_id
        connection
        isSocial
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateQmsuser(
    $user_id: String!
    $name: String
    $email: String
    $nickname: String
    $user_metadata: TypelessData
    $app_metadata: TypelessData
    $role: String
  ) {
    updateQmsuser(
      user_id: $user_id
      name: $name
      email: $email
      nickname: $nickname
      app_metadata: $app_metadata
      user_metadata: $user_metadata
      role: $role
    ) {
      user_id
      email
      name
      nickname
      picture
      app_metadata
      user_metadata
      role
      identities {
        provider
        user_id
        connection
        isSocial
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteQmsuser($user_id: String!) {
    deleteQmsuser(user_id: $user_id)
  }
`;

export const GET_USER = gql`
  query qmsuser($userId: String) {
    qmsuser(user_id: $userId) {
      user_id
      email
      name
      nickname
      picture
      app_metadata
      user_metadata
      role
      identities {
        provider
        user_id
        connection
        isSocial
      }
    }
  }
`;

export const GET_PAGINATION_USERS = gql`
  query users($page: Int!, $per_page: Int!, $sort: String!, $q: String) {
    users(page: $page, per_page: $per_page, sort: $sort, q: $q) {
      total
      start
      limit
      length
      users {
        _id: user_id
        email
        name
        picture
        role
        app_metadata
        user_metadata
        identities {
          provider
          user_id
          connection
          isSocial
        }
      }
    }
  }
`;

export const UPDATE_USER_PASSWORD = gql`
  mutation ChangePassword($user_id: String!, $password: String!) {
    changePassword(user_id: $user_id, password: $password) {
      user_id
      email
      name
      nickname
      picture
      app_metadata
      user_metadata
      role
      identities {
        provider
        user_id
        connection
        isSocial
      }
    }
  }
`;

export const GET_USERS_BY_EMAIL = gql`
  query GetUsersWithSameEmail($email: String!, $sub: String!) {
    qmsUserByEmail(email: $email, sub: $sub) {
      created_at
      updated_at
      email
      email_verified
      identities {
        provider
        user_id
        connection
        isSocial
      }
      identity_api
      name
      nickname
      picture
      user_id
      tenantid
      oid
      user_metadata
      app_metadata
      last_login
      last_ip
      logins_count
      last_password_reset
      role
    }
  }
`;

export const MERGE_USER = gql`
  mutation MergeQmsUser(
    $primaryUserIdentity: TypelessData!
    $secondaryUserIdentity: TypelessData!
  ) {
    mergeQmsUser(
      primaryUserIdentity: $primaryUserIdentity
      secondaryUserIdentity: $secondaryUserIdentity
    ) {
      connection
      user_id
      provider
      profileData
      isSocial
    }
  }
`;
