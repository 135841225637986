import { ActionType } from '../Actions/actionTypes';

export const productPaginationDataReducer = (state = {}, action: any) => {
  if (action.type === ActionType.GET_PRODUCT_DATA) {
    return { ...state, ...action.payload };
  }
  return state;
};

export const productReducer = (state: any = null, action: any) => {
  if (action.type === ActionType.ADD_PRODUCT) {
    return action.payload;
  }
  return state;
};

export const setProductReducer = (state: any = null, action: any) => {
  if (action.type === ActionType.GET_PRODUCT) {
    return action.payload;
  }
  return state;
};
