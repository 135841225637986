import React, { useEffect } from 'react';

import ToggleSwitch from '../../Basic/ToggleSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import QuestionTextInput from '../QuestionTextInput';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';

const RatingQuestion = (props: any) => {
  const { data } = props;
  const { choices, setChoices } = data;

  const { control, formState, register, reset, watch, setValue, getValues } =
    useFormContext<any>();

  const { errors } = formState;
  const [isNPS, customAnswerText, numberOfChoices] = watch([
    'isNPS',
    'customAnswerText',
    'numberOfChoices',
  ]);

  const handleFocus = (event: any) => {
    event.currentTarget?.select();
  };
  const getQuestionsDataForForm = (question: any) => {
    let formData: any = {};
    formData = {
      title: question?.title || null,
      label: question?.label || null,
      desc: question?.desc || null,
      ratings: question?.choices || [],
    };
    setChoices(question.ratings);
    return formData;
  };

  useEffect(() => {
    const questionsData = getQuestionsDataForForm(data.question);

    reset({
      ...questionsData,
      optional: data.question?.attributes?.optional,
      showNavigation: data.question?.attributes?.showNavigation,
      isNPS: data.question?.attributes?.isNPS,
      customAnswerText: !data.question?.attributes?.showAsNumbers,
      numberOfChoices: data.question?.attributes?.numberOfChoices,
    });
  }, [data.question, data.questionnaire, reset, setChoices, getValues]);

  const addChoice = (choices: any) => {
    const choiceCount = choices?.length;
    let newState: any = [];
    if (choiceCount < 11) {
      newState = [
        ...choices,
        {
          rating: `${choices.length + 1}`,
          label: choices.length + 1,
        },
      ];
      return newState;
    }

    return choices;
  };

  const deleteChoice = () => {
    return choices.filter(
      (choice: any, index: number) => index !== numberOfChoices - 1
    );
  };

  const addMultipleChoices = (choices: any, choiceCount: number) => {
    let updatedChoices = choices;
    while (updatedChoices.length < choiceCount) {
      const choiceList = addChoice(updatedChoices);
      updatedChoices = choiceList;
    }

    return updatedChoices;
  };

  const handleNPSChange = (event: any) => {
    if (event) {
      const updatedChoices = addMultipleChoices(choices, 11);
      setChoices(updatedChoices);
      setValue('numberOfChoices', 11);
    }
    setValue('isNPS', event);
  };

  const onDragEnd = (result: any, language: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder: any = reorder(
      choices,
      result.source.index,
      result.destination.index
    );

    setChoices(newOrder);
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleChoiceValue = (value: string, item: any, index: number) => {
    let updatedChoices = Object.values({
      ...choices,
      [index]: { label: value, rating: item.rating },
    });
    setChoices(updatedChoices);
  };

  const handleChoiceChange = (event: any) => {
    const value = event.target.value;
    let updatedChoices = [];
    if (Number(value) > numberOfChoices) {
      updatedChoices = addChoice(choices);
    } else {
      updatedChoices = deleteChoice();
    }
    setValue('numberOfChoices', value);
    setChoices(updatedChoices);
  };

  const getInitialChoiceObject = () => {
    const initialChoices: any = [{ rating: '1', label: 1 }];
    return initialChoices;
  };

  const handleCustomAnswerTextOptionChange = (event: boolean) => {
    if (event) {
      const { numberOfChoices } = getValues();
      const questionsData = getQuestionsDataForForm(data.question);

      if (!questionsData?.choices?.length) {
        const initChoices = getInitialChoiceObject();
        const updatedChoices = addMultipleChoices(initChoices, numberOfChoices);
        setChoices(updatedChoices);
      }
    }
    setValue('customAnswerText', event);
  };

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='row m-0'>
                <div className='col col-8 pt-4'>
                  <div className='form-group'>
                    <input
                      className={`${errors.label ? 'is-invalid' : ''}
                       italic-input half-width-form-control `}
                      placeholder='Question label...'
                      type='text'
                      {...register(`label`, {
                        required: true,
                      })}
                      disabled={data.isLoading}
                    />
                  </div>
                  <div className='form-group'>
                    <div
                      className={`bottom-border-input italic-input ${
                        errors.title ? 'is-invalid' : ''
                      }`}
                    >
                      <Controller
                        control={control}
                        name={`title`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Start typing question text...'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                    <div className='no-border-input italic-input'>
                      <Controller
                        control={control}
                        name={`desc`}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Add description to your question'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {customAnswerText && (
                <div className='row m-0'>
                  <div className='col col-8 pt-4 pb-5'>
                    <DragDropContext
                      onDragEnd={(result) =>
                        onDragEnd(result, data.questionnaire?.defaultLanguage)
                      }
                    >
                      <Droppable droppableId='droppable'>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.droppableProps}
                          >
                            {choices?.map((item: any, index: number) => {
                              return (
                                <Draggable
                                  draggableId={item?.rating?.toString()}
                                  index={index}
                                  key={item.rating}
                                >
                                  {(provided) => (
                                    <div
                                      key={index}
                                      className='choices-list'
                                      ref={provided.innerRef}
                                      {...provided.dragHandleProps}
                                      {...provided.draggableProps}
                                    >
                                      <div className='choice-wrapper'>
                                        <QuestionTextInput
                                          style={{
                                            display: 'inline-block',
                                            width: '100%',
                                          }}
                                          placeholder={item.label}
                                          onChange={(value: string) => {
                                            const updatedValue =
                                              sanitizeQuillEditorOutput(value);
                                            return handleChoiceValue(
                                              updatedValue,
                                              item,
                                              index
                                            );
                                          }}
                                          onFocus={handleFocus}
                                          value={item.label}
                                          disabled={data.isLoading}
                                        />
                                      </div>
                                    </div>
                                  )}
                                </Draggable>
                              );
                            })}
                            {provided.placeholder}
                          </div>
                        )}
                      </Droppable>
                    </DragDropContext>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='optional'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Optional</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='hideBackButton'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Hide back button</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='isNPS'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={handleNPSChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Treat as NPS</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='customAnswerText'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={handleCustomAnswerTextOptionChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Custom answer text</label>
          </div>
          {!isNPS && (
            <div className='form-group'>
              <label className='mb-1'>Number of choices</label>
              <Controller
                control={control}
                name='numberOfChoices'
                rules={{ required: true }}
                defaultValue={10}
                render={({ field: { value } }) => (
                  <input
                    className={`${
                      errors?.numberOfChoices ? 'is-invalid' : ''
                    } italic-input`}
                    onChange={(event) => {
                      handleChoiceChange(event);
                    }}
                    type='number'
                    min={2}
                    max={11}
                    value={value}
                  />
                )}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default RatingQuestion;
