import { ActionType } from './actionTypes';

export const setProductDataToStore = (
  paginationData: any = {
    previous: null,
    next: null,
    hasPrevious: false,
    hasNext: false,
    total: 0,
    results: [],
  }
) => {
  return {
    type: ActionType.GET_PRODUCT_DATA,
    payload: paginationData,
  };
};

export const displayToastMessage = (toast: any = null) => {
  return {
    type: ActionType.DISPLAY_TOAST,
    payload: toast,
  };
};

export const setProduct = (product: any = null) => {
  return {
    type: ActionType.GET_PRODUCT,
    payload: product,
  };
};
