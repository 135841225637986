import regions from '../Config/region.config.json';

class Region {
  public regionName: string;
  public regionCode: string;
  public appUrl: string;
  public s3imageStore: string;
  public imageUrl: string;

  constructor(
    regionName: string,
    regionCode: string,
    appUrl: string,
    s3imageStore: string,
    imageUrl: string
  ) {
    this.regionName = regionName;
    this.regionCode = regionCode;
    this.appUrl = appUrl;
    this.s3imageStore = s3imageStore;
    this.imageUrl = imageUrl;
  }
}

export class RegionFactory {
  private regionsList: Region[];

  constructor() {
    const dataRegions = regions as any;
    this.regionsList = Object.keys(regions).map((region: any) => {
      return {
        regionCode: region,
        regionName: dataRegions[region].regionName,
        appUrl: dataRegions[region].appUrl,
        s3imageStore: dataRegions[region].S3imageStore,
        imageUrl: dataRegions[region].imageUrl,
      };
    });
  }

  getRegions() {
    return this.regionsList;
  }

  getRegion(regionCode: string) {
    return this.regionsList.find(
      (region: Region) => region.regionCode === regionCode
    );
  }
}
