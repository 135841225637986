// Theme colors
export const TableTheme = {
  tTextPrimary: '#606060',
  tBg: '#FFFFFF',
  tHeadBoder: '#4481bf',
  tRowBorder: '#C6C6C6',
};

// Custom Styles
export const customTable = {
  rows: {
    style: {
      minHeight: '74px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      borderBottom: '2px solid #4481BF',
      fontSize: '18px',
      fontWeight: 'bold',
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontSize: '18px',
    },
  },
};

export const customModal = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '35%',
    minHeight: '30%',
  },
};
