import ReactHtmlParser from 'react-html-parser';

const RatingQuestionView = ({ data }: any) => {
  const { questionnaire, question } = data;
  const defaultLanguage = questionnaire.defaultLanguage;
  const questionData = question?.questions.find(
    (questionData: any) =>
      questionData?.language?.code === defaultLanguage?.code
  );

  return (
    <div>
      <div className='form-group'>
        <label>Selected language</label>
        <div>{defaultLanguage.name}</div>
      </div>
      <div className='form-group'>
        <label>Question Text</label>
        <div className='input-text'>
          {ReactHtmlParser(questionData?.text?.main)}
        </div>
      </div>
      <div className='form-group'>
        <label>Description</label>
        <div className='input-text'>
          {ReactHtmlParser(questionData?.text?.sub)}
        </div>
      </div>
      {question.attributes?.showAsNumbers === false && (
        <div className='form-group'>
          <label>Choices</label>
          <div>
            {questionData?.text?.ratings?.map((rating: any, index: number) => {
              return (
                <div className='input-text' key={index}>
                  {ReactHtmlParser(rating?.label)}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default RatingQuestionView;
