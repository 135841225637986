import React, { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';

import ToggleSwitch from '../../Basic/ToggleSwitch';
import QuestionTextInput from '../QuestionTextInput';

const StartingPage = (props: any) => {
  const { data } = props;

  const { control, register, reset, formState } = useFormContext<any>();

  const { errors } = formState;

  const getQuestionsDataForForm = (question: any) => {
    return {
      label: question?.label || null,
      title: question?.title || null,
      desc: question?.desc || null,
    };
  };

  useEffect(() => {
    const questionsData = getQuestionsDataForForm(data.question);

    reset({
      ...questionsData,
      languageToggle: data.question?.attributes?.languageToggle,
      showNavigation: data.question?.attributes?.showNavigation,
      hideBackButton: data.question?.attributes?.hideBackButton,
    });
  }, [data.question, data.questionnaire, reset]);

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='col col-8 pt-4'>
                <div className='form-group'>
                  <input
                    className={`${
                      errors.label ? 'is-invalid' : ''
                    } italic-input half-width-form-control `}
                    placeholder='Question label...'
                    type='text'
                    {...register(`label`, {
                      required: true,
                    })}
                    disabled={data.isLoading}
                  />
                </div>
                <div className='form-group'>
                  <div
                    className={`bottom-border-input italic-input ${
                      errors.label ? 'is-invalid' : ''
                    }`}
                  >
                    <Controller
                      control={control}
                      name={`title`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <QuestionTextInput
                          placeholder='Start typing question text...'
                          onChange={(value: string) => {
                            const updatedValue =
                              sanitizeQuillEditorOutput(value);
                            return onChange(updatedValue);
                          }}
                          value={value}
                          disabled={data.isLoading}
                        />
                      )}
                    />
                  </div>
                  <div className='no-border-input italic-input'>
                    <Controller
                      control={control}
                      name={`desc`}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <QuestionTextInput
                          placeholder='Add description to your question'
                          onChange={(value: string) => {
                            const updatedValue =
                              sanitizeQuillEditorOutput(value);
                            return onChange(updatedValue);
                          }}
                          value={value}
                          disabled={data.isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.settings && (
        <div>
          <div className='q-settings__wrapper'>
            <div className='q-settings__catLabel'>Activation</div>
            <div className='form-group d-flex'>
              <Controller
                control={control}
                name='languageToggle'
                rules={{ required: false }}
                render={({ field: { onChange, value = false } }) => (
                  <ToggleSwitch
                    handleSwitch={onChange}
                    checked={value}
                    width={36}
                  />
                )}
              />
              <label className='ml-2'>Language toggle</label>
            </div>
            <div className='form-group d-flex'>
              <Controller
                control={control}
                name='hideBackButton'
                rules={{ required: false }}
                render={({ field: { onChange, value = false } }) => (
                  <ToggleSwitch
                    handleSwitch={onChange}
                    checked={value}
                    width={36}
                  />
                )}
              />
              <label className='ml-2'>Hide back button</label>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default StartingPage;
