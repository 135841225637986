import React, { Fragment, useEffect, useState, useReducer } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLock,
  faTrash,
  faUnlockAlt,
} from '@fortawesome/free-solid-svg-icons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';

import ToggleSwitch from '../../Basic/ToggleSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import QuestionTextInput from '../QuestionTextInput';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';
const ChoicesQuestion = ({ data }: any) => {
  const { choices, setChoices } = data;
  const [maxNumberOfChoices, setMaxNumberOfChoices] = useState<number>(
    data.question?.attributes?.numberOfChoices || choices?.length
  );
  const { control, formState, register, reset, setValue, getValues } =
    useFormContext<any>();

  const { errors } = formState;
  useEffect(() => {
    const initializeChoices = () => {
      return [
        { _id: '1', content: '' },
        { _id: '2', content: '' },
      ];
    };

    const setChoiceValues = (question: any) => {
      let choices: any = {};

      if (question?.choices?.length > 0) {
        choices = question.choices;
      } else {
        choices = initializeChoices();
      }

      setChoices(choices);
    };

    const getQuestionsDataForForm = (question: any) => {
      let formData: any = {};

      formData = {
        title: question?.title || null,
        label: question?.label || null,
        desc: question?.desc || null,
      };

      return formData;
    };

    const questionsData = getQuestionsDataForForm(data.question);

    setChoiceValues(data.question);
    reset({
      ...questionsData,
      optional: data.question?.attributes?.optional,
      randomize: data.question?.attributes?.randomize,
      numberOfChoices: data.question?.attributes?.numberOfChoices,
      lowestNumberOfChoices: data.question?.attributes?.lowestNumberOfChoices,
    });
  }, [data.question, data.questionnaire, setChoices, reset]);

  const handleFocus = (event: any) => {
    event.currentTarget?.select();
  };

  useEffect(() => {
    setValue('numberOfChoices', maxNumberOfChoices);
  }, [maxNumberOfChoices, setValue]);

  const handleChoiceValue = (value: string, item: any, language: any) => {
    const updatedChoice = choices.map((choice: any) => {
      if (choice._id === item._id) {
        return {
          ...choice,
          content: value,
        };
      }
      return choice;
    });
    setChoices(updatedChoice);
  };
  const exclusifyChoice = (exclusive: boolean, item: any) => {
    const updatedChoice = choices.map((choice: any) => {
      if (choice._id === item._id) {
        return {
          ...choice,
          exclusive: exclusive,
        };
      }
      return choice;
    });
    setChoices(updatedChoice);
  };

  const handleAddChoices = (event: any) => {
    event.preventDefault();

    const { numberOfChoices } = getValues();

    const choiceCount = choices.length;

    if (choiceCount < 15) {
      let newState = choices;

      newState = [
        ...choices,
        {
          _id: `${choices.length + 1}`,
          content: '',
        },
      ];
      const updatedChoiceCount = newState.length;
      setMaxNumberOfChoices(
        Number(numberOfChoices) < Number(updatedChoiceCount - 1)
          ? Number(numberOfChoices)
          : updatedChoiceCount
      );
      setChoices(newState);
    }
  };

  const onDragEnd = (result: any, language: any) => {
    if (!result.destination) {
      return;
    }

    const newOrder: any = reorder(
      choices[language.code],
      result.source.index,
      result.destination.index
    );

    setChoices(newOrder);
  };

  const deleteChoice = (event: any, id: number) => {
    event.preventDefault();
    const updatedChoices = choices.filter((item: any) => item._id !== id);

    const { numberOfChoices } = getValues();

    const updatedChoiceCount = updatedChoices.length;

    setMaxNumberOfChoices(
      Number(numberOfChoices) < Number(updatedChoiceCount)
        ? Number(numberOfChoices)
        : updatedChoiceCount
    );
    setChoices(updatedChoices);
  };

  const reorder = (list: any, startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  const [, forceUpdate] = useReducer((x) => x + 1, 0);
  const [MCQ, setMCQ] = useState(!data.singleChoice);
  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='row m-0'>
                <div className='col col-8 pt-4'>
                  <div className='form-group'>
                    <input
                      className={`${
                        errors.label ? 'is-invalid' : ''
                      } italic-input half-width-form-control `}
                      placeholder='Question label...'
                      type='text'
                      {...register(`label`, {
                        required: true,
                      })}
                      disabled={data.isLoading}
                    />
                  </div>
                  <div className='form-group'>
                    <div
                      className={`bottom-border-input italic-input ${
                        errors.title ? 'is-invalid' : ''
                      }`}
                    >
                      <Controller
                        control={control}
                        name={`title`}
                        rules={{ required: true }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Start typing question text...'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                    <div className='no-border-input italic-input'>
                      <Controller
                        control={control}
                        name={`desc`}
                        rules={{ required: false }}
                        render={({ field: { onChange, value } }) => (
                          <QuestionTextInput
                            placeholder='Add description to your question'
                            onChange={(value: string) => {
                              const updatedValue =
                                sanitizeQuillEditorOutput(value);
                              return onChange(updatedValue);
                            }}
                            value={value}
                            disabled={data.isLoading}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className='row m-0'>
                <div className='col col-8 pt-4 pb-5'>
                  <DragDropContext
                    onDragEnd={(result) =>
                      onDragEnd(result, data.questionnaire?.defaultLanguage)
                    }
                  >
                    <Droppable droppableId='droppable'>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {choices?.map((item: any, index: number) => {
                            return (
                              <Draggable
                                draggableId={item._id}
                                index={index}
                                key={item._id}
                              >
                                {(provided) => (
                                  <div
                                    key={index}
                                    className='choices-list'
                                    ref={provided.innerRef}
                                    {...provided.dragHandleProps}
                                    {...provided.draggableProps}
                                  >
                                    <div className='choice-wrapper'>
                                      <QuestionTextInput
                                        onChange={(value: string) => {
                                          const updatedValue =
                                            sanitizeQuillEditorOutput(value);
                                          return handleChoiceValue(
                                            updatedValue,
                                            item,
                                            data.questionnaire?.defaultLanguage
                                          );
                                        }}
                                        onFocus={handleFocus}
                                        value={item.content}
                                        disabled={data.isLoading}
                                        placeholder={'New choice'}
                                      />
                                    </div>
                                    &nbsp;&nbsp;
                                    {MCQ && (
                                      <div
                                        title={
                                          item.exclusive
                                            ? 'click to remove exclusive option'
                                            : 'click to set this as exclusive option'
                                        }
                                        onClick={() => {
                                          exclusifyChoice(
                                            !item.exclusive,
                                            item
                                          );
                                          forceUpdate();
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={
                                            item.exclusive
                                              ? faLock
                                              : faUnlockAlt
                                          }
                                        />
                                      </div>
                                    )}
                                    &nbsp;&nbsp;
                                    <div
                                      title='remove'
                                      onClick={(event) =>
                                        deleteChoice(event, item._id)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        color='red'
                                      />
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <button
                      className='btn-add-new'
                      onClick={(event: any) => handleAddChoices(event)}
                    >
                      + Add new
                    </button>
                  </DragDropContext>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='optional'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Optional</label>
          </div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='randomize'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Randomize answers</label>
          </div>
          {data.singleChoice && (
            <div className='form-group d-flex'>
              <Controller
                control={control}
                name='MCQ'
                rules={{ required: false }}
                render={({ field: { onChange, value = false } }) => (
                  <ToggleSwitch
                    handleSwitch={(e: any) => {
                      onChange(e);
                      setMCQ(e);
                    }}
                    checked={value}
                    width={36}
                  />
                )}
              />
              <label className='ml-2'>Multiple Choice</label>
            </div>
          )}
          {!data.singleChoice && (
            <div className='form-group d-flex'>
              <Controller
                control={control}
                name='CQ'
                rules={{ required: false }}
                render={({ field: { onChange, value = false } }) => (
                  <ToggleSwitch
                    handleSwitch={(e: any) => {
                      onChange(e);
                      setMCQ(!e);
                    }}
                    checked={value}
                    width={36}
                  />
                )}
              />
              <label className='ml-2'>Single Choice</label>
            </div>
          )}

          {MCQ && (
            <Fragment>
              <div className='form-group'>
                <label className='mb-1'>Maximum answers</label>
                <input
                  className='italic-input'
                  type='number'
                  min={1}
                  max={!MCQ ? 1 : choices?.length}
                  {...register(`numberOfChoices`, {
                    value: 1,
                    required: true,
                  })}
                  onChange={(event) => {
                    setMaxNumberOfChoices(event.target.valueAsNumber);
                  }}
                />
              </div>
              <div className='form-group'>
                <label className='mb-1'>Minimum answers</label>
                <input
                  className='italic-input'
                  type='number'
                  min={1}
                  max={!MCQ ? 1 : choices?.length}
                  {...register(`lowestNumberOfChoices`, {
                    value: 1,
                    required: true,
                  })}
                />
              </div>
            </Fragment>
          )}
        </div>
      )}
    </>
  );
};

export default ChoicesQuestion;
