import { gql } from '@apollo/client';

export const GET_PAGINATED_SHARING_METHODS = gql`
  query GetSharingMethods(
    $questionnaireId: ID!
    $sortBy: SharingMethodSortingOption
    $sortOrder: SortOrder
    $limit: Int!
    $next: String
    $previous: String
  ) {
    sharingMethods(
      questionnaireId: $questionnaireId
      sortBy: $sortBy
      sortOrder: $sortOrder
      limit: $limit
      next: $next
      previous: $previous
    ) {
      results {
        _id
        name
        createdAt
        updatedAt
        questionnaireId
        type
        status
        data
        maxResponses
        linkDataSetId
        link
        surveyUUID
      }
      previous
      hasPrevious
      next
      hasNext
      total
    }
  }
`;

export const ADD_SHARING_METHOD = gql`
  mutation AddSharingMethod(
    $name: String!
    $questionnaireId: ID!
    $type: String!
    $maxResponses: Int
    $linkDataSetId: ID
  ) {
    addSharingMethod(
      name: $name
      type: $type
      questionnaireId: $questionnaireId
      maxResponses: $maxResponses
      linkDataSetId: $linkDataSetId
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      type
      status
      data
      linkDataSetId
      questionnaireId
    }
  }
`;

export const UPDATE_SHARING_METHOD = gql`
  mutation UpdateSharingMethod(
    $sharingMethodId: ID!
    $name: String!
    $maxResponses: Int
    $questionnaireId: ID!
    $linkDataSetId: ID
  ) {
    updateSharingMethod(
      _id: $sharingMethodId
      name: $name
      questionnaireId: $questionnaireId
      maxResponses: $maxResponses
      linkDataSetId: $linkDataSetId
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      type
      status
      data
      linkDataSetId
      questionnaireId
    }
  }
`;

export const UPDATE_SHARING_METHOD_STATUS = gql`
  mutation UpdateSharingMethodStatus(
    $surveyId: ID!
    $questionnaireId: ID!
    $setActive: Boolean!
  ) {
    updateSharingMethodStatus(
      surveyId: $surveyId
      questionnaireId: $questionnaireId
      setActive: $setActive
    ) {
      s
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      type
      data
      linkDataSetId
      questionnaireId
    }
  }
`;

export const LINK_DEVICE = gql`
  mutation LinkDevice(
    $name: String!
    $licenseId: ID!
    $questionnaireId: ID!
    $sharingMethodId: ID!
    $deviceId: String!
  ) {
    linkDevice(
      licenseId: $licenseId
      sharingMethodId: $sharingMethodId
      questionnaireId: $questionnaireId
      name: $name
      deviceId: $deviceId
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      type
      data
      linkDataSetId
      questionnaireId
    }
  }
`;

export const UPDATE_LINKED_DEVICES = gql`
  mutation UpdateLinkedDevices(
    $sharingMethodId: ID!
    $deviceList: [LinkedDeviceList]!
  ) {
    updateLinkedDevices(
      sharingMethodId: $sharingMethodId
      deviceList: $deviceList
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      type
      data
      linkDataSetId
      questionnaireId
    }
  }
`;
