import { LinkTypes, QuestionTypes } from './types';
import * as questionnaireStyles from '../Config/styles.config.json';

const numbers = '0123456789';
const lowercase = 'abcdefghijklmnopqrstuvwxyz';
const uppercase = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
export const alphaNumbericAlphabet = numbers + lowercase + uppercase;

export const formatDate = (date: Date) => {
  return date.toISOString().split('T')[0];
};

const ParentQuestions = [
  QuestionTypes.RATING,
  QuestionTypes.SINGLE_CHOICE,
  QuestionTypes.MULTIPLE_CHOICE,
  QuestionTypes.RATING_PAGE,
  QuestionTypes.CHOICE_PAGE,
  QuestionTypes.CONDITIONAL,
  QuestionTypes.RANKING,
];

export const isValidParentQuestion = (questionType: any) => {
  return ParentQuestions.indexOf(questionType) !== -1;
};

const InvalidFormQuestions = [
  QuestionTypes.CUSTOM_FINAL,
  QuestionTypes.FINAL,
  QuestionTypes.LANDING,
  QuestionTypes.CUSTOM_LANDING,
  QuestionTypes.INFO,
  QuestionTypes.FORM,
];

export const isValidFormQuestion = (questionType: any) =>
  InvalidFormQuestions.indexOf(questionType) === -1;

export const dateWithMonthsDelay = (months: number) => {
  const date = new Date();
  date.setMonth(date.getMonth() + months);
  return date;
};

export const getInitialColorStyles = (styles: any) => {
  return questionnaireStyles.styles.map(
    (style: { id: number; key: string; label: string; value: string }) => {
      const color = styles[`${style.key}`];
      return {
        ...style,
        value: color || style.value,
      };
    }
  );
};

export const sanitizeQuillEditorOutput = (value: string) => {
  return value.replace('<p><br></p>', '');
};

export const sharingMethods = [
  { value: LinkTypes.OPEN_LINK, label: 'Open link' },
  { value: LinkTypes.UNIQUE_LINK, label: 'Unique link' },
  { value: LinkTypes.PORTAL_LINK, label: 'Portal link' },
  { value: LinkTypes.ONE_SHOT_LINK, label: 'One shot link' },
  { value: LinkTypes.KIOSK, label: 'Kiosk' },
];
