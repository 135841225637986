import { useAuth0 } from '@auth0/auth0-react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

const UserQuickAccessPanel = (props: any) => {
  const { logout } = useAuth0();

  const handleLogOut = (event: any) => {
    event.preventDefault();
    localStorage.clear();
    logout({
      returnTo: window.location.origin,
    });
  };

  return (
    <div className='user-profile'>
      {props.openPanel && (
        <div className={'user-profile-panel'}>
          <div className={'line-item'}>
            <div className='notify-area__user--picture'>
              <img
                src={props.user?.picture}
                alt='Profile'
                className='user-img'
              />
              <div className='lbl-txt'>
                <p className='lbl-txt__title--name'>{props.user?.name}</p>
                <p className='lbl-txt__subtitle'>
                  {props.loggedInUser?.roles?.map(
                    (role: string, index: number, self: string[]) =>
                      role.charAt(0).toUpperCase() +
                      role.slice(1) +
                      `${self.length - 1 > index ? ', ' : ''}`
                  )}
                </p>
              </div>
            </div>
          </div>
          <div className={'line-item'}>
            <div className='lbl-txt__wrapper'>
              <p className='lbl-txt__subtitle'>Personal</p>
              <NavLink className='lbl-txt__link' to='/profile#profile'>
                Edit Profile
              </NavLink>
              {/* <NavLink
                className='lbl-txt__link'
                to='/settings'
                onClick={(event) => event.preventDefault()}
              >
                Settings
              </NavLink> */}
            </div>
          </div>
          <div className={'line-item'}>
            <div className='lbl-txt__wrapper'>
              <p className='lbl-txt__subtitle'>Support</p>
              {/* <NavLink
                className='lbl-txt__link'
                to='/settings'
                onClick={(event) => event.preventDefault()}
              >
                Help
              </NavLink> */}
              <NavLink
                className='lbl-txt__link'
                to='/'
                onClick={(event) => handleLogOut(event)}
              >
                Log out
              </NavLink>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loggedInUser: state.user,
  };
};

export default connect(mapStateToProps)(UserQuickAccessPanel);
