import React from 'react';
import DataTable, { createTheme } from 'react-data-table-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEdit } from '@fortawesome/free-solid-svg-icons';

import { TableTheme, customTable } from '../../Constants/ThemeO2T';
import Loading from '../Basic/Loading';
import AccessControl from '../AccessControl';
import Alert from '../Basic/AlertComponent';

// O2T Create Table Theme
createTheme(
  'o2t-table',
  {
    text: {
      primary: TableTheme.tTextPrimary,
    },
    background: {
      default: TableTheme.tBg,
    },
    divider: {
      default: TableTheme.tRowBorder,
    },
  },
  'light'
);

const UsersGrid = ({ data }: any) => {
  const paginationUsers = data.users || [];
  const totalRows = data.total || null;

  const onPageChange = (page: number) => {
    data.handlePageChange(page - 1, data.perPage, data.sort);
  };

  const onPerRowsChange = (perPage: number) => {
    data.handlePageChange(data.page, perPage, data.sort);
  };

  // Table columns configuration
  const columns = [
    {
      name: 'Name',
      selector: (row: any) => row.name,
      width: '30%',
      cell: (row: any) => (
        <div className='grid-col'>
          <div className='grid-col__avatar'>
            <img src={row.picture} alt='Avatar' />
          </div>
          <div>
            <div className='grid-col__title'>{row.name}</div>
            <div className='grid-col__subtitle'>
              {row.role
                ? row.role.map(
                    (role: string, index: number, self: string[]) =>
                      role.charAt(0).toUpperCase() +
                      role.slice(1) +
                      `${self.length - 1 > index ? ', ' : ''}`
                  )
                : ''}
            </div>
          </div>
        </div>
      ),
    },
    {
      name: 'E-mail',
      width: '20%',
      selector: (row: any) => row.email,
    },
    {
      name: 'Language',
      width: '10%',
      selector: (row: any) => (row.language ? row.language : 'English'),
    },
    {
      name: 'Identity provider',
      width: '15%',
      selector: (row: any) => {
        return `${row.identities
          .map((identity: any) => ` ${identity.provider}`)
          .toString()}`;
      },
    },
    {
      name: 'Database',
      selector: (row: any) => {
        return `${row.identities
          .map((identity: any) => ` ${identity.connection}`)
          .toString()}`;
      },
    },
    {
      button: true,
      cell: (row: any) => (
        <div className='actions-col' id={row._id}>
          <AccessControl allowedPermissions={['update:user']}>
            <button
              data-testid='edit-user-btn'
              onClick={() => {
                onUserEditClick(row);
              }}
            >
              <FontAwesomeIcon icon={faEdit} />
            </button>
          </AccessControl>
          <span className='divider'>|</span>
          <AccessControl allowedPermissions={['delete:user']}>
            <button
              data-testid='delete-user-btn'
              onClick={() => onUserDeleteClick(row)}
              className='btn-delete'
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </AccessControl>
        </div>
      ),
    },
  ];

  // Handle EDIT click event
  const onUserEditClick = (user: any) => {
    data.handleEditUserSelect(user);
  };

  // Handle DELETE click event
  const onUserDeleteClick = (user: any) => {
    data.handleDeleteUserSelect(user);
  };

  if (data.error) return <pre>Something went wrong in Data Table</pre>;

  return (
    <>
      <AccessControl
        allowedPermissions={['list:users']}
        renderNoAccess={() => (
          <Alert
            message={'Unauthorized. You do not have permission to view users'}
          />
        )}
      >
        <DataTable
          columns={columns}
          data={paginationUsers}
          theme='o2t-table'
          customStyles={customTable}
          progressPending={data.loading}
          progressComponent={<Loading />}
          pagination={true}
          paginationServer={true}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={onPerRowsChange}
          onChangePage={onPageChange}
        />
      </AccessControl>
    </>
  );
};

export default UsersGrid;
