import { ActionType } from '../Actions/actionTypes';

export const userPaginationDataReducer = (state = {}, action: any) => {
  if (action.type === ActionType.GET_USER_DATA) {
    return { ...state, ...action.payload };
  }
  return state;
};

export const loggedInUserReducer = (state = {}, action: any) => {
  if (action.type === ActionType.GET_USER) {
    return action.payload;
  } else if (action.type === ActionType.GET_USER_LICENSES) {
    return {
      ...state,
      licenses: action.payload,
    };
  }
  return state;
};
