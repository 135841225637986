import { ActionType } from './actionTypes';

export const setSearchData = (
  searchData: any = {
    searchTerm: '',
    isOpen: false,
  }
) => {
  return {
    type: ActionType.GET_SEARCH_DATA,
    payload: searchData,
  };
};
