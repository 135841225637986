import { gql } from '@apollo/client';

export const GET_PAGINATED_LINK_DATA_SETS = gql`
  query GetLinkDataSets(
    $searchTerm: String
    $sortBy: LinkDataSetSortingOption
    $sortOrder: SortOrder
    $limit: Int!
    $next: String
    $previous: String
  ) {
    linkDataSets(
      searchTerm: $searchTerm
      sortBy: $sortBy
      sortOrder: $sortOrder
      limit: $limit
      next: $next
      previous: $previous
    ) {
      results {
        _id
        createdBy
        updatedBy
        createdAt
        updatedAt
        name
        data
        dataType
      }
      previous
      hasPrevious
      next
      hasNext
      total
    }
  }
`;

export const ADD_LINK_DATA_SET = gql`
  mutation AddLinkDataSet(
    $name: String!
    $dataType: String!
    $data: [JSONObject]
  ) {
    addLinkDataSet(name: $name, dataType: $dataType, data: $data) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      data
      dataType
    }
  }
`;

export const UPDATE_LINK_DATA_SET = gql`
  mutation UpdateLinkDataSet($linkDataSetId: ID!, $name: String!) {
    updateLinkDataSet(_id: $linkDataSetId, name: $name) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      data
      dataType
    }
  }
`;

export const DELETE_LINK_DATA_SET = gql`
  mutation DeleteLinkDataSet($linkDataSetId: ID!) {
    deleteLinkDataSet(_id: $linkDataSetId)
  }
`;

export const GET_LINK_DATA_SETS_BY_IDS = gql`
  query GetLinkDataSetsByIds($ids: [ID]) {
    linkDataSetsByIds(ids: $ids) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      name
      data
      dataType
    }
  }
`;
