import ReactHtmlParser from 'react-html-parser';

const ChoiceQuestionView = ({ data }: any) => {
  const { questionnaire, question } = data;
  const defaultLanguage = questionnaire.defaultLanguage;
  const questionData = question.questions.find(
    (questionData: any) => questionData.language?.code === defaultLanguage?.code
  );

  return (
    <div>
      <div className='form-group'>
        <label>Selected language</label>
        <div>{defaultLanguage.name}</div>
      </div>
      <div className='form-group'>
        <label>Question Text</label>
        <div className='input-text'>
          {ReactHtmlParser(questionData?.text?.main)}
        </div>
      </div>
      <div className='form-group'>
        <label>Description</label>
        <div className='input-text'>
          {ReactHtmlParser(questionData?.text?.sub)}
        </div>
      </div>
      {question.text?.choices?.length !== 0 && (
        <div className='form-group'>
          <label>Choices</label>
          <div>
            {questionData.text?.choices?.map((choice: any, index: number) => {
              return (
                <div className='input-text' key={choice._id}>
                  {ReactHtmlParser(choice?.content)}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default ChoiceQuestionView;
