import React from 'react';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { customModal } from '../../Constants/ThemeO2T';

const ContentSaveWarningModal: React.FC<any> = ({
  modalIsOpen,
  toggleModal,
  modalTitle,
}) => {
  return (
    <>
      <Modal
        isOpen={modalIsOpen}
        style={customModal}
        contentLabel='Unsaved Content Warning Modal'
      >
        <div className='o2t-modal'>
          <div className='o2t-modal__header'>
            <h2 className='o2t-modal__header-title'>{modalTitle}</h2>
          </div>

          <div className='o2t-modal__container'>
            <p>
              You have unsaved changes. Navigating away from this view will
              revert these changes. Please save before continuing.
            </p>
          </div>
          <div className='o2t-modal__footer'>
            <button onClick={toggleModal}>
              Ok
              <span>
                <FontAwesomeIcon className='ml-3' icon={faChevronRight} />
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ContentSaveWarningModal;
