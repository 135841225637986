import React from 'react';
import { NavLink } from 'react-router-dom';

const NotFound = () => {
  return (
    <div className='notFound-page'>
      <h1>404</h1>
      <h5>Page Not Found</h5>
      <span>The page you requested could not be found.</span>
      <NavLink to='/'>Back to Home</NavLink>
    </div>
  );
};

export default NotFound;
